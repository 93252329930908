@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&family=Poppins:wght@400;700&display=swap');

.content.Game {
  background-color: #000;
}
.enter-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  height: 600px;


  .logo {
    img {
      width: 200px; // Adjust as needed
      margin-bottom: 20px;
    }
  }

  .title {
    font-family: 'Orbitron', sans-serif;
    color: #ffd700; // Gold color
    font-size: 48px;
    margin-bottom: 40px;
  }

  .enter-button {
    background-color: #000;
    color: #fff;
    border: 2px solid #ffd700;
    padding: 15px 30px;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    outline: none;

    &:hover {
      background-color: #ffd700;
      color: #000;
      transform: scale(1.05);
    }
  }

  // Responsive styles for mobile devices
  @media (max-width: 600px) {
    .title {
      font-size: 36px; // Reduce the font size for smaller screens
      margin-bottom: 30px;
      text-align: center;
    }

    .enter-button {
      padding: 12px 24px;
      font-size: 16px;
    }

    .logo {
      img {
        width: 150px; // Reduce logo size on mobile
        margin-bottom: 15px;
      }
    }
  }
}