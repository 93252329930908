@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&family=Poppins:wght@400;700&display=swap');

.choose-game-type-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 0 20px;

  .header {
    font-family: 'Orbitron', sans-serif;
    color: #ffd700;
    font-size: 36px;
    margin-bottom: 40px;
    text-align: center;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .game-type-button {
      background-color: #000;
      color: #fff;
      border: 2px solid #ffd700;
      padding: 15px 30px;
      font-size: 18px;
      font-family: 'Poppins', sans-serif;
      border-radius: 10px;
      cursor: pointer;
      margin: 10px 0;
      transition: all 0.3s ease;
      width: 300px;

      &:hover {
        background-color: #ffd700;
        color: #000;
        transform: scale(1.05);
      }
    }

    .back-button {
      background-color: #ff0000;
      color: #fff;
      border: none;
      padding: 10px 20px;
      font-size: 16px;
      font-family: 'Poppins', sans-serif;
      border-radius: 10px;
      cursor: pointer;
      margin-top: 30px;
      transition: all 0.3s ease;

      &:hover {
        background-color: #ff6347;
        transform: scale(1.05);
      }
    }
  }

  @media (max-width: 600px) {
    .header {
      font-size: 28px;
      margin-bottom: 30px;
    }

    .buttons-container {
      .game-type-button {
        width: 80%;
        max-width: 300px;
        padding: 12px 24px;
        font-size: 16px;
      }

      .back-button {
        padding: 8px 16px;
        font-size: 14px;
      }
    }
  }
}