#countdown {
    body {
      font: normal 13px/20px Arial, Helvetica, sans-serif;
      word-wrap: break-word;
      color: #eee;
      background: #353535;
    }

    width: 312px;
    height: 71px;
    text-align: center;
    position: relative;
    background: #222;
    background-image: -o-linear-gradient(top, #222, #333, #333, #222);
    border: 1px solid #111;
    border-radius: 5px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
    margin: auto;
    padding: 24px 0;
    /* position: absolute; */
    top: -8px;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 20px;

    :before {
      content: "";
      width: 8px;
      height: 43px;
      background: #444;
      background-image: -webkit-linear-gradient(top, #555, #444, #444, #555);
      background-image: -moz-linear-gradient(top, #555, #444, #444, #555);
      background-image: -ms-linear-gradient(top, #555, #444, #444, #555);
      background-image: -o-linear-gradient(top, #555, #444, #444, #555);
      border: 1px solid #111;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      display: block;
      position: absolute;
      top: 2px;
      left: -10px;
    }

    :after {
      content: "";
      width: 8px;
      height: 43px;
      background: #444;
      background-image: -webkit-linear-gradient(top, #555, #444, #444, #555);
      background-image: -moz-linear-gradient(top, #555, #444, #444, #555);
      background-image: -ms-linear-gradient(top, #555, #444, #444, #555);
      background-image: -o-linear-gradient(top, #555, #444, #444, #555);
      border: 1px solid #111;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      display: block;
      position: absolute;
      top: 2px;
      right: -10px;
    }

     #tiles {
      position: relative;
      z-index: 1;
    }

     #tiles>span {
      width: 53px;
      font: bold 35px "Droid Sans", Arial, sans-serif;
      text-align: center;
      color: #111;
      background-color: #ddd;
      background-image: -webkit-linear-gradient(top, #bbb, #eee);
      background-image: -moz-linear-gradient(top, #bbb, #eee);
      background-image: -ms-linear-gradient(top, #bbb, #eee);
      background-image: -o-linear-gradient(top, #bbb, #eee);
      border-top: 1px solid #fff;
      border-radius: 3px;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.7);
      margin: 0 7px;
      // padding: 18px 0;
      display: inline-block;
      position: relative;
    }

     #tiles>span:before {
      content: "";
      width: 100%;
      height: 13px;
      background: #111;
      display: block;
      padding: 0 3px;
      position: absolute;
      top: 41%;
      left: -3px;
      z-index: -1;
    }

     #tiles>span:after {
      content: "";
      width: 100%;
      height: 1px;
      background: #eee;
      border-top: 1px solid #333;
      display: block;
      position: absolute;
      top: 48%;
      left: 0;
    }

     .labels {
      width: 100%;
      height: 25px;
      text-align: center;
      position: absolute;
      top: 5px;
    }

     .labels li {
      width: 67px;
      font: bold 15px "Droid Sans", Arial, sans-serif;
      color: #f47321;
      text-shadow: 1px 1px 0px #000;
      text-align: center;
      text-transform: uppercase;
      display: inline-block;
    }

  }
