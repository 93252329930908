#raffle *,
#raffle *::before,
#raffle *::after {
  box-sizing: border-box;
}
#raffle body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: #ECEFF1;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "DM Sans", system-ui, sans-serif;
  color: #263238;
}
#raffle .card {
  width: 22rem;
  max-width: 90%;
  border: solid 4px #FFF;
  border-radius: 2rem;
  padding: 0.75rem;
  text-decoration: none;
  color: inherit;
  background: #ECEFF1;
  box-shadow: 0 16px 32px #CFD8DC;
  transition: all 400ms ease;
}
#raffle .card.card1 {
  background: radial-gradient(black, transparent);
}
#raffle .card.card1 .card__body {
  background: #FFF;
  background: radial-gradient(black, transparent);
}
#raffle .card.card1 .card__body:hover {
  background: repeating-linear-gradient(45deg, black, transparent 100px);
}
#raffle .card.card2 {
  background: radial-gradient(#4169e1, transparent);
}
#raffle .card.card2 .card__body {
  background: #FFF;
  background: radial-gradient(#b1bbd8, #1e4fe0);
}
#raffle .card.card2 .card__body:hover {
  background: repeating-radial-gradient(#4169e1, transparent 100px);
}
#raffle .card.card-red {
  background: radial-gradient(#ff0000, transparent);
}
#raffle .card.card-red .card__body {
  background: #FFF;
  background: radial-gradient(#d27f8a, #cb1d1d);
}
#raffle .card.card-red .card__body:hover {
  background: repeating-radial-gradient(#f5001c, transparent 100px);
}
#raffle .card.card-gold {
  background: radial-gradient(#ffd700, transparent);
}
#raffle .card.card-gold .card__body {
  background: #FFF;
  background: radial-gradient(circle, #FFD700 60%, #FFA500 90%);
}
#raffle .card.card-gold .card__body:hover {
  background: repeating-radial-gradient(#ffd700, transparent 100px);
}
#raffle .card.card3 {
  background: radial-gradient(#497184, transparent);
}
#raffle .card.card3 .card__body {
  background: #b0bec5;
  background: radial-gradient(#cfd8dc, transparent);
}
#raffle .card.card3 .card__body:hover {
  background: repeating-linear-gradient(52deg, skyblue, transparent 64px);
}
#raffle .card__body {
  border-radius: 1.25rem;
  padding: 0.75rem;
}
#raffle .card__top {
  display: flex;
  font-weight: 500;
}
#raffle .card__owner, #raffle .card__creator {
  width: 50%;
  display: flex;
}
#raffle .card__owner {
  position: relative;
}
#raffle .card__owner::after {
  content: "";
  position: absolute;
  top: 0;
  right: 7px;
  width: 2px;
  height: 100%;
  border-radius: 2px;
  background: #ECEFF1;
}
#raffle .card__creator {
  flex-direction: row-reverse;
  text-align: right;
}
#raffle .card__avatar {
  width: 4rem;
  height: 4rem;
  background: #ECEFF1;
  border-radius: 100%;
  flex-shrink: 0;
  padding: 17px;
  text-align: center;
}
#raffle .card__user {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#raffle .card__user__title {
  font-size: 0.75rem;
  color: ghostwhite;
}
#raffle .card__image {
  padding-top: 75%;
  position: relative;
  overflow: hidden;
  margin-top: 0.75rem;
  border-radius: 1rem;
  background: #ECEFF1;
  box-shadow: 0 16px 32px #ECEFF1;
}
#raffle .card__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#raffle .card__info {
  margin: 0.75rem;
  display: flex;
  justify-content: space-between;
}
#raffle .card__info p {
  margin: 0;
}
#raffle .card__footer {
  background: #FFF;
  padding: 0.75rem;
  border-radius: 0.75rem 0.75rem 1.75rem 1.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#raffle .card__btn {
  width: 48%;
  padding: 1rem;
  text-align: center;
  font-weight: 500;
  transition: all 200ms ease;
}
#raffle .card__btn--primary {
  background: #263238;
  color: #ECEFF1;
  border-radius: 0.5rem 0.5rem 1.25rem 0.5rem;
}
#raffle .card__btn--primary:hover {
  background: #304FFE;
}
#raffle .card__btn--secondary {
  border-radius: 0.5rem 0.5rem 0.5rem 1.25rem;
}
#raffle .card__btn--secondary:hover {
  background: #ECEFF1;
  color: #304FFE;
}
#raffle .card input:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

