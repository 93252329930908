// $--container-width: 12800px;
$--container-width: 100%;

$--transition: 0.5s ease-in-out;

$--text-color: #fff;
$--gray-color: #2e2d2f;
$--black-color: #121212;

$color_1: black;
$color_2: white;
$font-family_1: inherit;
$font-family_2: "Poppins", sans-serif;
$background-color_1: transparent;
$background-color_2: #992d2d;
$background-color_3: #fff;
$background-color_4: #2e2d2f;
$background-color_5: #121212;
$background-color_6: #eb5e5e;
$background-color_7: #7a1d1d;
$background-color_8: rgba(255, 255, 255, 0.5);
@import "https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap";

#slider {
  /* ------------responsive, feel free to adapt it as you want-------------- */

  line-height: normal;
  width: 100%;

  * {
    // margin: 0;
    // padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  button {
    width: 100%;
    outline: none;
    background-color: $background-color_1;
    color: $color_1;
    font-family: $font-family_1;
    font-weight: 700;
  }

  &:root {
    --container-width: 1280px;
    --text-color: #fff;
    --gray-color: #2e2d2f;
    --black-color: #121212;
    --transition: 0.5s ease-in-out;
  }

  .strawberry {
    --color-light: #eb5e5e;
    --color-medium: #992d2d;
    --color-dark: #7a1d1d;
  }

  .mango {
    --color-light: #f4ce71;
    --color-medium: #d8a711;
    --color-dark: #e78f37;
  }

  .grape {
    --color-light: #a7e074;
    --color-medium: #6ba32d;
    --color-dark: #547a1d;
  }

  .peach {
    --color-light: #e76e8b;
    --color-medium: #a44f66;
    --color-dark: #732336;
  }

  .orange {
    --color-light: #ff9a00;
    --color-medium: #cf6400;
    --color-dark: #7a2b12;
  }

  section {
    font-family: $font-family_2;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    user-select: none;
  }

  .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: $--container-width;
    height: 100vh;
    aspect-ratio: 16 / 9;
    border-radius: 20px;
    // padding: 120px 0 120px 80px;
    color: $color_2;
    // background-color: $background-color_2;
    transition: $--transition;
    overflow: hidden;

    @media (max-width: 64em) {
      padding: 0px;

      @media (max-width: 64em) and (orientation: portrait) {
        aspect-ratio: 7/9;
      }

    }

    .navigation {
      list-style-type: none;
      width: 80px;
      height: 95%;
      font-size: 3rem;
      font-weight: 700;

      @media (max-width: 64em) {
        width: 35%;
      }

    }

    .content {
      width: 580px;
      height: 100%;
      position: relative;
      overflow: hidden;

      @media (max-width: 64em) {
        width: 100%;
      }
    }

    .fullscreen {
      width: 100%;
    }

    .slider {
      --item-width: 350px;

      &.timecomp {
        --item-width: 100%; // updated width when .timecomp is also applied
      }

      position: relative;
      display: flex;
      align-items: center;
      flex: 1;
      height: 100%;
      // margin-left: 36px;
      overflow-x: hidden;

      @media (max-width: 64em) {
        visibility: hidden;
        display: none;
      }
    }
  }

  .navigation {
    .navigation-item {
      position: relative;
      display: flex;
      align-items: flex-start;
      width: 100%;
      height: 24px;
      opacity: 40%;
      margin: 12px 0;
      transition: $--transition;
    }
  }

  .navigation-item {
    >* {
      position: absolute;
      transform-origin: top left;
      transition: inherit;
    }

    span {
      cursor: pointer;

      &:nth-child(1) {
        display: block;
        width: 6px;
        height: 6px;
        top: -3px;
        border-radius: 6px;
        background-color: $background-color_3;
      }

      &:nth-child(2) {
        top: 0;
        left: 18px;
        line-height: 1;
        transform: scale(0.65) translateY(-50%);
      }
    }
  }

  .navigation-item.active {
    height: calc(100% - 36px * 4);
    opacity: 1;

    span {
      &:nth-child(1) {
        top: 0;
        height: 100%;
      }

      &:nth-child(2) {
        transform: scale(1) translateY(0%);

        @media only screen and (max-device-width: 290px) and (orientation: portrait) {
          transform: scale(0.8) translateY(0%);
        }

        @media only screen and (min-device-width: 300px) and (orientation: portrait) {
          scale: 0.7
        }

      }
    }
  }

  .content {
    .content-wrapper {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      height: 95%;
      pointer-events: none;
      transition: $--transition;
    }
  }

  .content-wrapper.show {
    pointer-events: all;

    >* {
      transform: translateY(0);
      transition-delay: calc(var(--index) * 0.1s);
      opacity: 1;
    }

    @media only screen and (max-device-width: 290px) and (orientation: portrait) {
      height: 88%;
    }

    .fade {
      transition: opacity 0.5s ease-in-out;
      animation: fade 2s linear;
      // align-self: center;
    }

    @keyframes fade {
      0% {
        opacity: 0;
      }

      20% {
        opacity: 10%;
      }

      50% {
        opacity: 40%
      }

      100% {
        opacity: 1
      }
    }

    .view {
      height: auto;
      border-radius: 15px;
      // width: 90%;
      align-self: center;
      z-index: 1000;
      opacity: 0.7;


      @media only screen and (min-device-width: 280px) and (orientation: portrait) {
        height: 65%;
      }

      @media only screen and (min-device-width: 300px) and (orientation: portrait) {
        width: 100%;
      }

      @media only screen and (min-device-width: 900px) and (orientation: portrait) {
        width: 100%;
      }
    }

    img.view {
      width: 100% !important;
    }
  }

  .content-wrapper {
    >* {
      opacity: 0;
      transform: translateY(calc((4 - var(--index)) * 200px));
      transition: inherit;
      transition-delay: calc((3 - var(--index)) * 0.1s);
    }

    .juice-flavor {
      --index: 0;
      font-size: 2.4rem;
      // line-height: 1;
      padding: 10px;
      text-transform: capitalize;
      align-self: center;
      text-align: center;

      @media(max-width: 64em) and (orientation: portrait) {
        font-size: 25px;
      }
    }

    .fade.visible {
      @media(max-width: 64em) and (orientation: portrait) {
        width: 90%;
      }
    }

    .juice-describe {
      --index: 1;
      font-size: 1.6rem;
      font-weight: 400;
      // text-align: center;

      @media only screen and (min-width:600px) {
        margin: 10px
      }

      .description {
        @media only screen and (max-width: 768px) {
          font-size: small;
        }
      }
    }

    .juice-nutrition {
      --index: 2;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 18px;
      // margin-top: 24px;

      @media only screen and (min-width:600px) {
        margin: 10px
      }

      align-items: center;
      position: relative;
      // left: -24px;
    }

    .add-to-cart {
      --index: 3;
      display: flex;
      align-items: center;
      // justify-content: center;
      align-self: center;
    }
  }

  .juice-nutrition {
    h4 {
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 600;
    }

    .juice-nutrition-items {
      list-style-type: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;

      @media (max-width: 64em) {
        height: 225px;
        height: 135px;
        overflow: scroll;
      }

      @media only screen and (max-device-width: 290px) and (orientation: portrait) {
        height: 88%;
        transform: scale(0.8) translateY(0%);
      }
    }
  }

  .juice-nutrition-items {
    .juice-nutrition-item {
      width: 100px;
      height: 100px;
      background-color: $background-color_4;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      transition: $--transition;
    }
  }

  .juice-nutrition-item {
    >* {
      transition: inherit;
    }

    span {
      &:nth-child(1) {
        font-size: 0.66rem;
        margin-top: 11px;
      }

      &:nth-child(2) {
        font-size: 1.2rem;
        font-weight: 700;
      }

      &:nth-child(3) {
        font-size: 1.2rem;
        width: 100%;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $background-color_5;
        font-weight: 600;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: $background-color_6;

      span {
        &:nth-child(3) {
          background-color: $background-color_7;
        }
      }
    }
  }

  .add-to-cart {
    .add-to-cart-btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 250px;
      width: 100%;
      height: 60px;
      border-radius: 60px;
      padding: 5px;
      background-color: $background-color_4;
    }

    .heart {
      font-size: 3.6rem;
      margin-left: 2.4rem;
    }

    >* {
      transition: 0.25s ease-in-out;

      &:hover {
        cursor: pointer;
        transform: scale(0.95);
      }
    }
  }

  .add-to-cart-btn {
    span {
      &:nth-child(1) {
        font-size: 2rem;
        font-weight: 600;
        text-transform: uppercase;
        margin-right: 24px;
      }
    }

    button {
      margin: 5px;
    }

    .lButtonListWrapper {
      columns: 120px auto;
    }


    /* button list */

    .lButton {
      background: WhiteSmoke;
      color: #000;
      padding: 9px 15px;
      border: 1px solid silver;
      border-radius: 5px;
    }

    .lButton:hover {
      background: #fff;
      cursor: pointer;
    }

    .lButton4 {
      background: radial-gradient(#fff, gainsboro);
      color: #000;
      padding: 9px 15px;
      border: 3px solid silver;
      border-bottom: 3px solid gray;
      border-right: 3px solid gray;
      border-radius: 15px;
    }

    .lButton4:hover {
      background: WhiteSmoke;
      cursor: pointer;
    }

    .cart-icon {
      display: grid;
      place-items: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      font-size: 2rem;
      background-color: $background-color_6;
      transition: 0.5s ease-in-out;
    }
  }

  .slider {
    .next-btn {
      position: absolute;
      left: calc(var(--item-width) - 50px);
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 2px solid var(--text-color);
      font-size: 2.4rem;
      text-transform: uppercase;
      display: grid;
      place-items: center;
      z-index: 2;
      transition: 0.25s ease-in-out;
      color: aqua;

      @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
        left: calc(var(--item-width) - 100px);
      }

    }

    .prev-btn {
      position: absolute;
      left: calc(var(--item-width) - 50px);
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 2px solid var(--text-color);
      font-size: 2.4rem;
      text-transform: uppercase;
      display: grid;
      place-items: center;
      z-index: 2;
      transition: 0.25s ease-in-out;
      color: aqua;

      @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
        left: calc(var(--item-width) - 100px);
      }
    }

    &::before {
      position: absolute;
      content: "";
      width: 2px;
      background-color: $background-color_3;
      height: calc(100% - 100px);
      left: 0;
      z-index: 100;
    }

    &::after {
      position: absolute;
      content: "";
      width: 2px;
      background-color: $background-color_3;
      height: calc(100% - 200px);
      left: var(--item-width);
    }


    .slider-wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      height: 100%;
      list-style-type: none;
      overflow: hidden;
      transform: translateX(calc(var(--index) * var(--item-width) * (-1)));
      transition: $--transition;
      // z-index: 1;
    }
  }

  .next-btn {
    top: 0;

    &:hover {
      cursor: pointer;
      background-color: $background-color_8;
    }

    &:active {
      transform: scale(0.9);
    }
  }

  .prev-btn {
    bottom: 0;

    &:hover {
      cursor: pointer;
      background-color: $background-color_8;
    }

    &:active {
      transform: scale(0.9);
    }
  }

  .slider-wrapper {
    .slider-item {
      width: var(--item-width);
      height: 100%;
    }
  }

  .slider-item.active {
    img {
      filter: brightness(100%);
    }
  }

  .slider-item {

    img {
      width: 100%;
      height: 100%;
      //object-fit: cover;
      object-fit: contain;
      filter: brightness(70%);
      transition: $--transition;
      background-color: black;
      cursor: pointer;
    }

    // &::after {
    //     position: absolute;
    //     content: "";
    //     width: 2px;
    //     background-color: $background-color_3;
    //     height: calc(100% - 200px);
    //     right: var(--item-width);
    // }

  }

}