#packCarousel {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  height: 100dvh;
  display: grid;
  place-items: center;
  overflow: hidden;
  background-color: black;

  main {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100vw;
    overflow: hidden;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  }

  .slider {
    --x-off: 0px;
  }

  .slide {
    width: 200px;
    height: 300px;
    list-style-type: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    box-shadow: 0 20px 30px rgba(255, 255, 255, 0.3) inset;
    transition: transform 0.1s, left 0.75s, top 0.75s, width 0.75s, height 0.75s;
    opacity: 0;

    &.active {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: none;
      border-radius: 0;
      box-shadow: none;
      opacity: 1;
    }

    &.prev {
      left: calc(var(--x-off) + 50% - 220px); /* Adjust based on positioning */
      // opacity: 1;
    }
    &.next {
      left: calc(var(--x-off) + 50%);
      opacity: 1;
      z-index: 1;

    }
    &.proceeding {
      left: calc(var(--x-off) + 50% + 220px); /* Adjust based on positioning */
      opacity: 1;
      z-index: 1;
    }    
  }

  .visual canvas {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }

  .content {
    width: min(26vw, 400px);
    position: absolute;
    top: 50%;
    left: 3rem;
    transform: translateY(-50%);
    font: 400 0.85rem helvetica, sans-serif, system-ui;
    color: white;
    text-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
    hyphens: auto;
    opacity: 0;
    display: none;

    background-color: black;
    border-radius: 6px;
    opacity: 0.7%;
    padding: 8px;
    
    & .title {
      font-family: "arial-black", system-ui;
      text-transform: uppercase;
    }

    & .description {
      line-height: 1.7;
      margin: 1rem 0 1.5rem;
      font-size: 0.8rem;
    }

    & button {
      width: fit-content;
      background-color: rgba(0, 0, 0, 0.1);
      color: white;
      border: 2px solid white;
      border-radius: 0.25rem;
      padding: 0.75rem;
      cursor: pointer;
    }
  }

  .slide.active .content {
    display: block;
    animation: show 0.75s ease-in-out 0.3s forwards;
  }

  @keyframes show {
    0% {
      filter: blur(5px);
      transform: translateY(calc(-50% + 75px));
    }

    100% {
      opacity: 1;
      filter: blur(0);
    }
  }

  .nav {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    user-select: none;
    display: flex;

    & .btn {
      background-color: rgba(255, 255, 255, 0.5);
      color: rgba(0, 0, 0, 0.7);
      border: 2px solid rgba(0, 0, 0, 0.6);
      margin: 0 0.25rem;
      padding: 0.75rem;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }

  .icon {
    width: 1em;
    height: 1em;
  }

  .icon,
  .icon * {
    pointer-events: none;
  }

  .icon svg {
    display: block;
    width: 100%;
    height: 100%;
    scale: 200%;
  }

  .ripple {
    --clr-ripple: #ccc6;
    --clr-ripple-effect: transparent;
    background-position: center;
    transition: background 0.6s;
  }

  .ripple:hover {
    background: var(--clr-ripple) radial-gradient(circle, transparent 1%, var(--clr-ripple) 1%) center/15000%;
  }

  .ripple:active {
    background-color: var(--clr-ripple-effect);
    background-size: 100%;
    transition: background 0s;
  }

  @media (width > 650px) and (width < 900px) {
    .content {
      & .title {
        font-size: 1rem;
      }

      & .description {
        font-size: 0.7rem;
      }

      & button {
        font-size: 0.7rem;
      }
    }

    .slide {
      width: 160px;
      height: 270px;

      &.prev {
        left: calc(var(--x-off) + 50% - 220px); /* Adjust based on positioning */
        // opacity: 1;
      }
      &.next {
        left: calc(var(--x-off) + 50%);
        opacity: 1;
        z-index: 1;
  
      }
      &.proceeding {
        left: calc(var(--x-off) + 50% + 170px); /* Adjust based on positioning */
        opacity: 1;
        z-index: 1;
      } 
    }
  }

  @media (width < 650px) {
    .content {
      & .title {
        font-size: 0.9rem;
      }

      & .description {
        font-size: 0.65rem;
      }

      & button {
        font-size: 0.7rem;
      }
    }

    .slide {
      width: 130px;
      height: 220px;

      &.prev {
        left: calc(var(--x-off) + 50% - 220px); /* Adjust based on positioning */
        // opacity: 1;
      }
      &.next {
        left: calc(var(--x-off) + 50%);
        opacity: 1;
        z-index: 1;
  
      }
      &.proceeding {
        left: calc(var(--x-off) + 50% + 140px); /* Adjust based on positioning */
        opacity: 1;
        z-index: 1;
      } 
    }
  }

}