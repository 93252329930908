.booster-card {
  position: relative;
  width: 200px;
  height: 250px;
  background-color: var(--card-bg-color);
  border: 2px solid var(--accent-color);
  border-radius: 15px;
  box-shadow: 0 4px 8px var(--shadow-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform var(--transition-speed), box-shadow var(--transition-speed), background-color var(--transition-speed);
  padding: 15px;
  text-align: center;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px var(--shadow-color);
    background-color: rgba(255, 215, 0, 0.1); // Light gold overlay on hover
  }

  .booster-quantity {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ff0000; // Red background for visibility
    color: #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    pointer-events: none; // Prevents the badge from capturing click events
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); // Adds a subtle shadow for depth
    transition: background-color 0.3s, transform 0.3s;
  }
  .booster-selected-quantity {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #0080ff; // Red background for visibility
    color: #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    pointer-events: none; // Prevents the badge from capturing click events
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); // Adds a subtle shadow for depth
    transition: background-color 0.3s, transform 0.3s;
  }

  &[data-quantity="1"] .booster-quantity {
    background-color: #ff4500; // OrangeRed for low quantity
  }

  &[data-quantity="0"] .booster-quantity {
    background-color: #555; // Grey for no quantity
  }

  .booster-icon {
    font-size: 48px;
    color: var(--accent-color);
    margin-bottom: 15px;
  }

  .booster-name {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .booster-description {
    font-size: 14px;
    color: var(--secondary-color);
  }

  &.selected-booster {
    background-color: #32cd32; // Green background
    color: #fff; // White text/icons
    border: 3px solid #fff;
    transform: scale(1.05); // Slight enlargement to indicate selection
  }

  &.used-booster {
    background-color: #555; // Grey background to indicate usage
    color: #fff; // White text/icons
    cursor: not-allowed;
    opacity: 0.8; // Slight transparency to indicate disabled state

    .booster-icon {
      color: #fff; // White icon color
    }

    &:hover {
      background-color: #555; // Maintain grey background on hover
      transform: none; // Prevent scaling on hover
    }
  }
}

@media (max-width: 768px) {
  .booster-card {
    width: 90%; // Reduce width to fit better on smaller screens
    height: auto; // Allow height to adjust based on content
    padding: 10px; // Reduce padding

    .booster-quantity {
      width: 20px;
      height: 20px;
      font-size: 12px;
      top: 8px;
      right: 8px;
    }
    .booster-selected-quantity {
      width: 20px;
      height: 20px;
      font-size: 12px;
      top: 8px;
      left: 8px;
    }

    .booster-icon {
      font-size: 36px; // Smaller icon size
      max-width: 80px; // Limit icon width
    }

    .booster-name {
      font-size: 18px; // Slightly smaller font
    }

    .booster-description {
      font-size: 14px; // Slightly smaller font
    }
  }
}

@media (max-width: 480px) {
  .booster-card {
    width: 100%; // Full width on very small screens
    padding: 8px; // Further reduce padding

    .booster-quantity {
      width: 18px;
      height: 18px;
      font-size: 10px;
      top: 6px;
      right: 6px;
    }
    .booster-selected-quantity {
      width: 18px;
      height: 18px;
      font-size: 10px;
      top: 6px;
      left: 6px;
    }

    .booster-icon {
      font-size: 28px; // Smaller icon size
      max-width: 60px; // Limit icon width
    }

    .booster-name {
      font-size: 16px; // Smaller font
    }

    .booster-description {
      font-size: 12px; // Smaller font
    }
  }

  #confirm-booster-button {
    padding: 8px 20px;
    font-size: 14px;
  }
}
