.packages {
  @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&family=Roboto:wght@300;400;500;700&display=swap");

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    // padding: 0;
    // margin: 0;
  }

  //Logo:eth 
  .fab.fa-ethereum {
    // font-size:48px;
    color: lightblue;
  }

  nav {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
  }

  nav ul,
  nav ul li {
    outline: 0;
  }

  nav ul li a {
    text-decoration: none;
  }

  img {
    width: 100%;
  }

  h1 {
    font-size: clamp(1.2rem, 3vw, 1.5rem);
  }

  font-family: "Nunito",
  sans-serif;
  display: flex;
  align-items: baseline;
  justify-content: center;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  /* MAIN MENU */

  main {
    display: grid;
    grid-template-columns: 11% 89%;
    width: 95%;
    margin: 40px;
    background: rgba(16, 21, 61, 0.5);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 15px;
    box-shadow: 0 0.5px 0 1px rgba(255, 255, 255, 0.23) inset,
      0 1px 0 0 rgba(255, 255, 255, 0.6) inset, 0 4px 16px rgba(0, 0, 0, 0.12);
    transition: height 0.5s ease-in-out, min-height 0.5s ease-in-out;
    /* Add this line */
    align-self: center;
  }

  .main-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 15px 0 0 15px;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    padding: 12px 0 20px;
    overflow: hidden;
    font-family: inherit;
  }

  .user-info img {
    padding: 12px 24px 6px;
    border-radius: 50%;
  }

  .user-info img.giff {
    padding: 12px 24px 6px;
    border-radius: 18%;
  }

  .user-info p {
    color: #fff;
    font-size: clamp(0.8rem, 3vw, 1rem);
    font-weight: 500;
    text-align: center;
    line-height: 1;
    padding: 0 6px 32px;
  }

  .nav-item {
    display: block;
  }

  .nav-item a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    padding: 12px 0;
    margin: 0 8px;
    border-radius: 5px;
  }

  .nav-item.active a {
    background: rgba(106, 109, 155, 0.5);
    text-decoration: none;
  }

  .nav-icon {
    width: 40px;
    height: 20px;
    font-size: 1.1rem;
  }

  .nav-text {
    display: block;
    width: 70px;
    height: 20px;
    letter-spacing: 0;
  }

  /* CONTENT */

  .content {
    display: grid;
    grid-template-columns: 75% 25%;

    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  /* LEFT CONTENT */

  .left-content {
    padding: 30px 20px;
    padding: 3px;

    color: #e5e5e5;
  }

  /* SLIDER */

  .slider-container {
    margin: 0 auto;
    width: 100%;
  }

  .swiper {
    width: 100%;
    padding: 16px 0 20px;
    margin-bottom: 50px;
  }

  .swiper-slide {
    position: relative;
    width: 600px;
  }

  .swiper-slide img {
    border-radius: 20px;
    height: 300px;
    object-fit: cover;
    border: 2px solid rgba(159, 160, 168, 0.5);

    &.packItem {
      object-fit: fill;
    }
  }

  .swiper-pagination {
    --swiper-pagination-bottom: -4px;
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: #ffffffe7;
    transition: all 0.3s ease-in-out;
  }

  .swiper-pagination-bullet-active {
    background-color: #fff;
    width: 18px;
    border-radius: 8px;
  }

  .slide-overlay {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    row-gap: 12px;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 98%;
    background: linear-gradient(240deg,
        transparent,
        transparent,
        rgba(38, 21, 149, 0.8));
    border-radius: 20px;
    padding: 30px;
  }

  .slide-overlay h2 {
    font-size: clamp(1.2rem, 3vw, 2.2rem);
    font-weight: 700;
  }

  .slide-overlay button {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    width: max-content;
    padding: 12px 16px;
    background: #e9e6eb2a;
    color: #e8e5e5;
    border: 1px solid rgba(159, 160, 168, 0.4);
    border-radius: 12px;
    outline: 0;
    font-size: clamp(0.7rem, 3vw, 1rem);
    font-weight: 500;
    text-transform: uppercase;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    transition: all 0.4s linear;
    cursor: pointer;
  }

  .slide-overlay button:is(:hover, :focus-visible) {
    background: #e9e6eb;
    color: #000;
  }

  .paused-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    /* White color for the pause icon */
    font-size: 3rem;
    /* Make the icon large */
    z-index: 10;
    /* Ensure it appears above other content */

    border-radius: 15px;

  }

  /* Containers of Artist and Albums */

  .containers {
    display: flex;
    align-items: center;
    padding: 0 0 12px;
    overflow-x: auto;
    cursor: grab;
  }

  /* ARTISTS */

  .artists h1 {
    margin-bottom: 24px;
  }

  .artist-container {
    column-gap: 20px;
  }

  .artist {
    display: grid;
    grid-auto-flow: dense;
    align-items: center;
    grid-template-rows: 8fr 2fr;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
  }

  .artist img {
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid rgba(169, 150, 253, 0.5);
    width: 140px;
    aspect-ratio: 1/1;
    margin-bottom: 8px;
    box-shadow: rgba(221, 221, 221, 0.3) 0px 6px 18px -3px,
      rgba(221, 221, 221, 0.2) 0px -3px 0px inset;
    transition: all 0.2s;
  }

  .artist img:hover {
    border: 4px solid rgba(169, 150, 253, 0.6);
  }

  .artist .image-wrapper {
    position: relative;
    display: inline-block;
  }

  .artist .image-wrapper img {
    // width: 100%;
    display: block;
    border-radius: 50%;
  }

  .artist .paused-overlay {
    //border-radius: 15px;
  }

  .artist p {
    font-size: clamp(0.9rem, 3vw, 1rem);
    font-weight: 500;
    text-align: center;
  }

  /* ALBUMS */

  .albums h1 {
    margin: 60px 0 14px;
  }

  .album-container {
    column-gap: 24px;
  }

  .album {
    display: grid;
    grid-auto-flow: dense;
    grid-template-rows: 5fr 2fr;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
  }

  .album-frame {
    position: relative;
    width: 180px;
    aspect-ratio: 1/1;
    border: 2px solid rgba(169, 150, 253, 0.5);
    border-radius: 10px;
    box-shadow: rgba(221, 221, 221, 0.3) 0px 8px 18px -3px,
      rgba(221, 221, 221, 0.2) 0px -3px 0px inset;
    margin-bottom: 15px;
    overflow: hidden;
  }

  .album-frame img {
    position: absolute;
    inset: 0;
    height: 100%;
    object-fit: cover;
    transition: transform 0.8s;
  }

  .album-frame img:hover {
    transform: rotate(3deg) scale(1.2);
  }

  .album h2 {
    font-size: clamp(0.9rem, 4vw, 1.1rem);
    font-weight: 500;
    line-height: 1.3;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .album p {
    font-size: clamp(0.9rem, 4vw, 1rem);
    opacity: 0.5;
  }

  /* Containers Scrollbar Style */

  .artist-container::-webkit-scrollbar,
  .album-container::-webkit-scrollbar {
    height: 10px;
  }

  .artist-container::-webkit-scrollbar-track,
  .album-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.3rem rgb(79, 78, 78);
    border-radius: 40px;
  }

  .artist-container::-webkit-scrollbar-thumb,
  .album-container::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0.5rem rgb(116, 116, 116);
    background-color: rgba(25, 43, 206, 0.2);
    outline: none;
    border-radius: 40px;
  }

  /* RIGHT CONTENT */

  .right-content {
    display: grid;
    grid-template-rows: 60% 40%;
    border-radius: 0 15px 15px 0;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    padding: 30px 9px;
    color: #e5e5e5;
  }

  /* SONGS */

  .recommended-songs h1 {
    margin-bottom: 24px;
  }

  .pack-container {
    align-items: center;
  }

  .pack {
    display: grid;
    grid-template-columns: 26% auto 32%;
    align-items: center;
    margin-bottom: 16px;
  }

  .pack-img {
    position: relative;
    width: 60px;
    border-radius: 6px;

    .paused-overlay {
      font-size: x-large;
    }
  }

  .pack-img img {
    aspect-ratio: 4/3;
    border-radius: inherit;
    object-fit: cover;
    border: 2px solid rgba(159, 160, 168, 0.5);
    box-shadow: rgba(221, 221, 221, 0.3) 0px 6px 18px -3px,
      rgba(221, 221, 221, 0.2) 0px -3px 0px inset;
  }

  .pack-img .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 92%;
    background-color: rgba(169, 150, 253, 0.6);
    border-radius: inherit;
    font-size: 1.75rem;
    opacity: 0;
    transition: all 0.4s linear;
    cursor: pointer;
  }

  .pack-img:hover .overlay {
    opacity: 1;
  }

  .pack h2 {
    font-size: 1rem;
  }

  .pack p,
  .pack span {
    font-size: 0.8rem;
    font-weight: 300;
  }

  .pack p {
    opacity: 0.8;
  }

  /* MUSIC PLAYER */

  .music-player {
    position: fixed;
    top: 17%;
    z-index: 20;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: rgba(188, 184, 198, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: inset 2px -2px 6px rgba(214, 214, 214, 0.2),
      inset -3px 3px 3px rgba(255, 255, 255, 0.3);
    border-radius: 16px;
    padding: 30px 20px;
    margin-top: 20px;

    .fa.fa-window-close {
      position: absolute;
      top: 1%;
      left: 87%;
      font-size: larger;
    }

    @media only screen and (max-width: 500px) {
      top: 5%;
    }
  }

  .album-cover {
    position: relative;
  }

  .album-cover img {
    border-radius: 50%;
    border: 2px solid rgba(222, 215, 255, 0.9);
    max-width: 120px;
    aspect-ratio: 1/1;
    object-fit: cover;
    box-shadow: 0 10px 60px rgba(200, 187, 255, 1.75);
    transition: transform 0.5s ease-out;
    pointer-events: none;
    user-select: none;
  }

  .point {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 16px;
    background-color: rgba(17, 6, 58, 0.7);
    border: 2px solid rgba(222, 215, 255, 0.9);
    aspect-ratio: 1/1;
    border-radius: 50%;
    z-index: 2;
  }

  .music-player h2 {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 16px 0 2px;
  }

  .music-player p {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 26px;
    opacity: 0.8;
  }

  /* Music Player Controls */

  #progress {
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: 6px;
    background: rgba(200, 187, 255, 0.6);
    border-radius: 4px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  #progress::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    background: rgb(77, 58, 162);
    width: 20px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 4px solid rgb(234, 229, 255);
    box-shadow: 0 6px 10px rgba(200, 187, 255, 0.4);
  }

  .controls {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .controls button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    aspect-ratio: 1/1;
    margin: 20px;
    background: rgba(200, 187, 255, 0.6);
    border-radius: 50%;
    border: 0;
    outline: 0;
    color: #fff;
    font-size: 1.1rem;
    box-shadow: 0 4px 8px rgba(200, 187, 255, 0.3);
    cursor: pointer;
    transition: all 0.3s linear;
  }

  .controls button:is(:hover, :focus-visible) {
    transform: scale(0.96);
  }

  .controls button:nth-child(2) {
    transform: scale(1.3);
  }

  .controls button:nth-child(2):is(:hover, :focus-visible) {
    transform: scale(1.25);
  }

  /* MEDIA QUERIES */

  @media (max-width: 1500px) {
    main {
      grid-template-columns: 6% 94%;
    }

    .user-info img {
      border-radius: 50%;
      padding: 12px 12px 6px;
    }

    .nav-icon {
      text-align: center;
      transform: translateY(2px);
    }

    .nav-text {
      display: none;
    }

    .content {
      grid-template-columns: 70% 30%;
      transition: grid-template-columns 1.2s ease-in-out .5s;
    }

    .content.Explore {
      grid-template-columns: auto 0%;
    }

    .content.Game {
      grid-template-columns: auto 0%;
    }

    .content.Basket {
      grid-template-columns: auto 0%;
    }
  }

  @media (max-width: 1310px) {
    main {
      grid-template-columns: 8% 92%;
      margin: 30px;
    }
  }

  @media (max-width: 1250px) {
    .swiper-slide {
      width: 500px;
    }

    .swiper-slide img {
      border-radius: 16px;
      height: 280px;
    }

    .artist {
      grid-template-rows: 7fr 2fr;
    }

    .artist img {
      width: 120px;
    }

    .album-frame {
      width: 160px;
    }

    .pack {
      grid-template-columns: 29% auto 10%;
    }

    .controls button {
      margin: 15px;
    }
  }

  @media (max-width: 1100px) {
    .content {
      grid-template-columns: 60% 40%;
    }

    .left-content {
      padding: 40px 20px 20px;
      padding: 3px;
    }

    .swiper-slide {
      width: 410px;
    }

    .swiper-slide img {
      height: 220px;
    }

    .artist {
      grid-template-rows: 5fr 2fr;
    }

    .artist img {
      width: 90px;
    }

    .album {
      grid-template-rows: 4fr 2fr;
    }

    .album-frame {
      width: 130px;
    }

    .pack {
      grid-template-columns: 26% 50% 10%;
    }

    .pack:nth-child(8),
    .pack:nth-child(9) {
      display: none;
    }
  }

  @media (max-width: 910px) {
    main {
      grid-template-columns: 10% 90%;
      margin: 8px;
    }

    .left-content {
      padding: 30px 20px 20px;
      padding: 3px;
    }

    .swiper-slide {
      width: 350px;
    }

    .swiper-slide img {
      height: 180px;
    }

    .artist {
      grid-template-rows: 4fr 2fr;
    }

    .artist img {
      width: 80px;
    }

    .album {
      grid-template-rows: 3fr 2fr;
    }

    .album-frame {
      width: 100px;
    }

    .right-content {
      grid-template-rows: 55% 45%;
    }

    .pack {
      grid-template-columns: 30% 50% 12%;
    }

    .pack:nth-child(7) {
      display: none;
    }
  }

  @media (max-width: 825px) {
    .content {
      grid-template-columns: 52% 48%;
    }

    .swiper-slide {
      width: 320px;
    }

    .swiper-slide img {
      height: 200px;
    }

    .slide-overlay {
      row-gap: 8px;
      padding: 12px 36px;
    }

    .slide-overlay button {
      padding: 8px 12px;
    }

    .pack {
      grid-template-columns: 28% 50% 10%;
    }
  }

  @media (max-width: 700px) {
    main {
      grid-template-columns: 15% 85%;
    }

    .content {
      grid-template-columns: 100%;
      grid-template-areas:
        "leftContent"
        "rightContent";
    }

    .left-content {
      grid-area: leftContent;
    }

    .slide-overlay {
      row-gap: 12px;
      padding: 20px 30px;
    }

    .swiper-slide {
      width: 420px;
    }

    .artist img {
      width: 110px;
    }

    .album {
      grid-template-rows: 3fr 2fr;
    }

    .album-frame {
      width: 140px;
    }

    .right-content {
      grid-area: rightContent;
      border-left: unset;
      grid-template-rows: 60% 40%;
      row-gap: 16px;
    }

    .pack {
      grid-template-columns: 18% 50% 8%;
    }

    .pack:nth-child(7),
    .pack:nth-child(8),
    .pack:nth-child(9) {
      display: grid;
    }

    .controls button {
      margin: 20px;
    }
  }

  @media (max-width: 580px) {
    .swiper-slide {
      width: 340px;
    }

    .swiper-slide img {
      height: 180px;
    }

    .artist img {
      width: 80px;
    }

    .album {
      grid-template-rows: 3fr 2fr;
    }

    .album-frame {
      width: 100px;
    }

    .pack {
      grid-template-columns: 23% 50% 8%;
    }
  }

  @media (max-width: 450px) {
    .user-info img {
      border-radius: 50%;
      padding: 6px 6px 2px;
    }

    .swiper-slide {
      width: 320px;
    }

    .slide-overlay {
      row-gap: 8px;
      padding: 12px 55px;
    }

    .pack {
      grid-template-columns: 25% 50% 8%;
    }
  }
}