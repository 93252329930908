#raffle {

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background: #ECEFF1;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'DM Sans', system-ui, sans-serif;
    color: #263238;
  }

  .card {
    width: 22rem;
    max-width: 90%;
    border: solid 4px #FFF;
    border-radius: 2rem;
    padding: .75rem;
    text-decoration: none;
    color: inherit;
    background: #ECEFF1;
    box-shadow: 0 16px 32px #CFD8DC;
    transition: all 400ms ease;

    &.card1 {
      background: radial-gradient(black, transparent);

      .card {
        &__body {
          background: #FFF;
          background: radial-gradient(black, transparent);
        }

        &__body:hover {
          background: repeating-linear-gradient(45deg, black, transparent 100px)
        }
      }
    }

    &.card2 {
      background: radial-gradient(#4169e1, transparent);

      .card {
        &__body {
          background: #FFF;
          background: radial-gradient(#b1bbd8, #1e4fe0);
        }

        &__body:hover {
          background: repeating-radial-gradient(#4169e1, transparent 100px)
        }
      }
    }

    &.card-red {
      background: radial-gradient(#ff0000, transparent);

      .card {
        &__body {
          background: #FFF;
          background: radial-gradient(#d27f8a, #cb1d1d);
        }

        &__body:hover {
          background: repeating-radial-gradient(#f5001c, transparent 100px)
        }
      }
    }

    &.card-gold {
      background: radial-gradient(#ffd700, transparent);

      .card {
        &__body {
          background: #FFF;
          background: radial-gradient(circle, #FFD700 60%, #FFA500 90%);
        }

        &__body:hover {
          background: repeating-radial-gradient(#ffd700, transparent 100px)
        }
      }
    }

    &.card3 {
      background: radial-gradient(#497184, transparent);

      .card {
        &__body {
          background: #b0bec5;
          background: radial-gradient(#cfd8dc, transparent);
        }

        &__body:hover {
          background: repeating-linear-gradient(52deg, skyblue, transparent 64px)
        }
      }
    }

    &:hover {
      // background: #FFF;
    }

    &__body {
      border-radius: 1.25rem;
      padding: .75rem;
    }

    &__top {
      display: flex;
      font-weight: 500;
    }

    &__owner,
    &__creator {
      width: 50%;
      display: flex;
    }

    &__owner {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 7px;
        width: 2px;
        height: 100%;
        border-radius: 2px;
        background: #ECEFF1;
      }
    }

    &__creator {
      flex-direction: row-reverse;
      text-align: right;
    }

    &__avatar {
      width: 4rem;
      height: 4rem;
      background: #ECEFF1;
      border-radius: 100%;
      flex-shrink: 0;

      padding: 17px;
      text-align: center;
    }

    &__user {
      padding: 0 .5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__title {
        font-size: .75rem;
        // color: #B0BEC5;
        color: ghostwhite;
      }
    }

    &__image {
      padding-top: 75%;
      position: relative;
      overflow: hidden;
      margin-top: .75rem;
      border-radius: 1rem;
      background: #ECEFF1;
      box-shadow: 0 16px 32px #ECEFF1;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__info {
      margin: .75rem;
      display: flex;
      justify-content: space-between;

      p {
        margin: 0;
      }
    }

    &__footer {
      background: #FFF;
      padding: .75rem;
      border-radius: .75rem .75rem 1.75rem 1.75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__btn {
      width: 48%;
      padding: 1rem;
      text-align: center;
      font-weight: 500;
      transition: all 200ms ease;

      &--primary {
        background: #263238;
        color: #ECEFF1;
        border-radius: .5rem .5rem 1.25rem .5rem;

        &:hover {
          background: #304FFE;
        }
      }

      &--secondary {
        border-radius: .5rem .5rem .5rem 1.25rem;

        &:hover {
          background: #ECEFF1;
          color: #304FFE;
        }
      }
    }

    input:disabled {
      background-color: #cccccc;
      color: #666666;
      cursor: not-allowed;
    }
  }
}