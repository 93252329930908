@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&family=Poppins:wght@400;700&display=swap');

.content.Game {
  background-color: #000;
}

.onchain-offchain-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; // Use min-height for better responsiveness



  padding: 0 20px; // Add padding to prevent content from touching edges

  .header {
    font-family: 'Orbitron', sans-serif;
    color: #ffd700;
    font-size: 36px;
    margin-bottom: 40px;
    text-align: center; // Center align text
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mode-button {
    background-color: #000;
    color: #fff;
    border: 2px solid #ffd700;
    padding: 15px 30px;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    border-radius: 10px;
    cursor: pointer;
    margin: 10px 0;
    transition: all 0.3s ease;
    width: 250px;

    &:hover {
      background-color: #ffd700;
      color: #000;
      transform: scale(1.05);
    }
  }

  .back-button {
    background-color: #FF0000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 30px;
    transition: all 0.3s ease;

    &:hover {
      background-color: #FF6347;
      transform: scale(1.05);
    }
  }

  // Responsive styles for mobile devices
  @media (max-width: 600px) {
    .header {
      font-size: 28px; // Reduce header font size
      margin-bottom: 30px;
    }

    .mode-button {
      width: 80%; // Make buttons take up more horizontal space
      max-width: 250px;
      padding: 12px 24px;
      font-size: 16px;
    }

    .back-button {
      padding: 8px 16px;
      font-size: 14px;
    }
  }
}