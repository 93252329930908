.P2PCard {
  place-self: center;
  width: 300px;

  /* SCSS Styling for the Card Collection Component */
  .p2pcard {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s ease;

    &:hover {
      transform: rotateX(15deg);
    }
  }

  .card-face {
    width: 100%;
    height: 95%;
    background-size: cover;
    background-position: center;
    backface-visibility: hidden;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .card-front {
    background-image: url('https://ipfs.io/ipfs/QmT6MYLMh4cBWniG2iMRaZe2MrQsiQ6yEdcTgfQrq6uf5F');
  }

  .card-back {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transform: rotateY(180deg);
  }

  /* Card 1 Individual Back Background */
  .p2pcard.TIER1 .card-back {
    background-image: url('https://ipfs.io/ipfs/QmPFX2vkAGYsJWBw5ozMWRVdwugKT7Jb4HfirktukcCc79');
  }

  /* Card 2 Individual Back Background */
  .p2pcard.TIER2 .card-back {
    background-image: url('https://ipfs.io/ipfs/QmPoPdjksSbajjF6Z7UY5PTZCzqHqHm9qurkQPfwzZJszc');
  }

  /* Card 3 Individual Back Background */
  .p2pcard.TIER3 .card-back {
    background-image: url('https://ipfs.io/ipfs/QmcjMoZq6UpdeUmiZfMxYBRZ66C5hm7GeBJJ1n36zW6M1Z');
  }

  /* Card 4 Individual Back Background */
  .p2pcard.TIER4 .card-back {
    background-image: url('https://ipfs.io/ipfs/QmX1YGLFXChsRW2dP1XYh7gyLMdfRjcwoV6BUxyBhKqSpp');
  }

  .p2pcard.TIER9 .card-back {
    background-image: url('https://ipfs.io/ipfs/QmPFX2vkAGYsJWBw5ozMWRVdwugKT7Jb4HfirktukcCc79');
  }

  .card-edge {
    position: absolute;
    background-color: #222;
  }

  .card-left {
    width: 3px;
    height: 100%;
    left: -1.5px;
    top: 0;
    transform-origin: left;
    transform: rotateY(90deg) translateZ(-1.5px);
  }

  .card-right {
    width: 3px;
    height: 100%;
    right: -1.5px;
    top: 0;
    transform-origin: right;
    transform: rotateY(-90deg) translateZ(-1.5px);
  }

  .card-top {
    width: 100%;
    height: 3px;
    top: -1.5px;
    left: 0;
    transform-origin: top;
    transform: rotateX(90deg) translateZ(-1.5px);
  }

  .card-bottom {
    width: 100%;
    height: 3px;
    bottom: -1.5px;
    left: 0;
    transform-origin: bottom;
    transform: rotateX(-90deg) translateZ(-1.5px);
  }

  .image-name-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.6s ease;
    flex-grow: 1;
    flex-grow: 0.5;
  }

  .image-container {
    width: 100%;
    height: 269px;
    display: flex;
    justify-content: center;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  }

  .name-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 3px;
    min-height: 55px;
    align-content: center;
    border-radius: 4px;
    margin-top: 1px;
  }

  .fighter-name,
  .opponent-name {
    font-size: 18px;
    color: #ffffff;
    text-transform: uppercase;
    padding: 3px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    flex: 1;
    text-align: center;
    -webkit-text-stroke: 1px #000;
    text-shadow: 1px 1px 0 #000, 2px 2px 0 #333;
  }

  .vs {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    margin: 0 15px;
  }

  .stats-table {
    margin-top: 1px;

    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 6px 5px;

      td {
        padding: 8px;
        font-size: 16px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 5px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &.highlighted {
          background-color: #FFD700;
          color: #000;
          border: 2px solid #ff0000;
          box-shadow: 0 0 15px rgba(255, 215, 0, 0.7);
        }
      }
    }
  }

  .card-footer {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: auto;
    margin-bottom: 10px;
  }

  .rarity,
  .tier {
    padding: 10px 15px;
    border-radius: 25px;
    font-weight: bold;
    text-align: center;
    flex-grow: 1;
    color: #ffffff;
  }

  .rarity {
    background-color: #800080;
    box-shadow: 0 0 10px rgba(128, 0, 128, 0.8), 0 0 20px rgba(128, 0, 128, 0.6);
    border: 2px solid #ffc107;
  }

  .tier {
    background-color: #FFD700;
    color: #000000;
    box-shadow: 0 0 10px rgba(255, 215, 0, 0.8), 0 0 20px rgba(255, 215, 0, 0.6);
    border: 2px solid #ffc107;
  }

  .rarity,
  .tier {
    padding: 10px 15px;
    border-radius: 25px;
    font-weight: bold;
    text-align: center;
    flex-grow: 1;
    color: #ffffff;
  }

  /* Rarity and Tier Styles */
  .rarity.ULTRA.RARE {
    background-color: #800080;
    box-shadow: 0 0 10px rgba(128, 0, 128, 0.8), 0 0 20px rgba(128, 0, 128, 0.6);
    border: 2px solid #ffc107;
  }

  .tier.TIER1 {
    background-color: #FFD700;
    color: #000000;
    box-shadow: 0 0 10px rgba(255, 215, 0, 0.8), 0 0 20px rgba(255, 215, 0, 0.6);
    border: 2px solid #ffc107;
  }

  .rarity.VERY.RARE {
    background-color: #FF4500;
    box-shadow: 0 0 10px rgba(255, 69, 0, 0.8), 0 0 20px rgba(255, 69, 0, 0.6);
    border: 2px solid #ffc107;
  }

  .tier.TIER2 {
    background-color: #C0C0C0;
    color: #000000;
    box-shadow: 0 0 10px rgba(192, 192, 192, 0.8), 0 0 20px rgba(192, 192, 192, 0.6);
    border: 2px solid #616160;
  }

  .rarity.STANDARD.RARE {
    background-color: #FFA500;
    box-shadow: 0 0 10px rgba(255, 165, 0, 0.8), 0 0 20px rgba(255, 165, 0, 0.6);
    border: 2px solid #ffc107;
  }

  .tier.TIER3 {
    background-color: #CD7F32;
    box-shadow: 0 0 10px rgba(205, 127, 50, 0.8), 0 0 20px rgba(205, 127, 50, 0.6);
    border: 2px solid #ffc107;
  }

  .rarity.COMMON {
    background-color: #32CD32;
    box-shadow: 0 0 10px rgba(50, 205, 50, 0.8), 0 0 20px rgba(50, 205, 50, 0.6);
    border: 2px solid #ffc107;
  }

  .tier.TIER4 {
    background-color: #1E90FF;
    box-shadow: 0 0 10px rgba(30, 144, 255, 0.8), 0 0 20px rgba(30, 144, 255, 0.6);
    border: 2px solid #0b237a;
  }


  /* Mobile Styling */
  @media screen and (max-width: 600px) {
    .card-container {
      width: 90%;
      max-width: none;
      max-height: 90vh;
    }

    .stats-table td {
      font-size: 16px;
    }

    .rarity,
    .tier {
      font-size: 15.4px;
    }

    .card-footer {
      margin-bottom: 10px;
    }
  }

  /* Reflective overlay on card face */
  .card-face::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle at var(--mouseX, 50%) var(--mouseY, 50%),
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0));
    transform: translateX(0) translateY(0);
    pointer-events: none;
    transition: opacity 0.2s ease-out;
    opacity: 0;
  }
}