.contract-button {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  border: 1px solid #ffd700;
  border-radius: 9999px;
  background: linear-gradient(to top right, #fff7cc, #ffeb99);
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.contract-button:hover {
  transform: scale(1.05);
}

.contract-icon {
  width: 20px;
  height: 20px;
  color: #ffcc00;
}

.contract-text {
  font-weight: bold;
  color: #8b8000;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.copied-text {
  color: #28a745;
  font-size: 0.75rem;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 64em) {

    @media (max-width: 64em) and (orientation: portrait) {
      max-width: 240px;
      /* Adjust width as needed */
    }

  }

}