*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: #eceff1;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "DM Sans", system-ui, sans-serif;
  color: #263238;
}

.fab.fa-ethereum {
  color:lightblue;
}
.card {
  width: 22rem;
  max-width: 90%;
  border: solid 4px #fff;
  border-radius: 2rem;
  padding: 0.75rem;
  text-decoration: none;
  color: inherit;
  background: #eceff1;
  box-shadow: 0 16px 32px #cfd8dc;
  transition: all 400ms ease;
  z-index: 1;
}
.card.card1 {
  background: radial-gradient(black, transparent);
}
.card.card1 .card__body {
  background: #fff;
  background: radial-gradient(black, transparent);
}
.card.card1 .card__body:hover {
  background: repeating-linear-gradient(45deg, black, transparent 100px);
}
.card.card2 {
  background: radial-gradient(#406b81, transparent);
}
.card.card2 .card__body {
  background: #fff;
  background: radial-gradient(#406b81, transparent);
}
.card.card2 .card__body:hover {
  background: repeating-radial-gradient(#406b81, transparent 100px);
}
.card.card3 {
  background: radial-gradient(#1688bc, transparent);
}
.card.card3 .card__body {
  background: #b0bec5;
  background: radial-gradient(#1688bc, transparent);
}
.card.card3 .card__body:hover {
  background: repeating-linear-gradient(52deg, skyblue, transparent 64px);
}
.card.card4 {
  background: radial-gradient(#68828e, transparent);
}
.card.card4 .card__body {
  background: #b0bec5;
  background: radial-gradient(#68828e, transparent);
}
.card.card4 .card__body:hover {
  background: repeating-linear-gradient(52deg, skyblue, transparent 64px);
}
.card:hover {
  /* background: #fff; */
}
.card__body {
  border-radius: 1.25rem;
  padding: 0.75rem;
}
.card__top {
  display: flex;
  font-weight: 500;
  justify-content: center;
}
.card__top .card_name {
  font-weight: bold;
  font-size: 1.5rem;
}
.card__owner, .card__creator {
  width: 50%;
  display: flex;
}
.card__owner {
  position: relative;
}
.card__owner::after {
  content: "";
  position: absolute;
  top: 0;
  right: 28px;
  width: 2px;
  height: 100%;
  border-radius: 2px;
  background: #eceff1;
}
.card__creator {
  flex-direction: row-reverse;
  text-align: right;
}
.card__avatar {
  width: 3rem;
  height: 3rem;
  background: #eceff1;
  background: linear-gradient(45deg, black, transparent);
  border-radius: 100%;
  flex-shrink: 0;
  padding: 17px;
  text-align: center;
}
.card__user {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card__user__title {
  font-size: 0.75rem;
  color: ghostwhite;
}
.card__image {
  padding-top: 75%;
  position: relative;
  overflow: hidden;
  margin-top: 0.75rem;
  border-radius: 1rem;
  background: #eceff1;
  box-shadow: 0 16px 32px #eceff1;
}
.card__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card__info {
  margin: 0.75rem;
  display: flex;
  justify-content: space-between;
}
.card__info p {
  margin: 0;
}
.card__footer {
  background: #fff;
  padding: 0.75rem;
  border-radius: 0.75rem 0.75rem 1.75rem 1.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card__btn {
  width: 48%;
  padding: 1rem;
  text-align: center;
  font-weight: 500;
  transition: all 200ms ease;
}

.card__btn--primary {
  background: #263238;
  color: #eceff1;
  border-radius: 0.5rem 0.5rem 1.25rem 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.card__btn--primary:disabled {
  background: #90a4ae; /* Lighter background color for disabled state */
  color: #cfd8dc;      /* Lighter text color for disabled state */
  cursor: not-allowed; /* Changes the cursor to indicate the button is disabled */
  opacity: 0.6;        /* Makes the button appear less prominent */
}
.card__btn--primary:hover {
  background: #304ffe;
}
.card__btn--secondary {
  border-radius: 0.5rem 0.5rem 0.5rem 1.25rem;
}
.card__btn--secondary:hover {
  background: #eceff1;
  color: #304ffe;
}
.card .tier {
  position: absolute;
  top: 5px;
  left: -33px;
  width: 127px;
}

