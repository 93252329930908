@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');


// GameSelection Component Styles
.game-selection-page {

  // Root Variables for Consistent Styling
  --primary-color: #fff;
  --accent-color: #FFD700; // Gold Color for Highlights
  --secondary-color: #adadad; // Grey for Subtexts
  --btn-hover-opacity: 0.8;
  --card-bg-color: rgba(30, 30, 30, 0.9);
  --shadow-color: rgba(255, 215, 0, 0.5);
  --transition-speed: 0.3s;


  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .game-selection-header {
    width: 100%;
    padding: 20px 0;
    box-sizing: border-box;
    position: relative;
    margin-top: 10px;

    .header-container {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
      max-width: 95%;
      margin: 0 auto;
    }

    .logo {
      cursor: pointer;

      img {
        width: 60px;
        height: auto;
        transition: opacity var(--transition-speed);

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .header-text {
      h1 {
        font-size: 36px;
        margin: 0;
        color: var(--accent-color);
      }

      p {
        font-size: 18px;
        margin: 5px 0 0 0;
        color: var(--secondary-color);
      }
    }
  }

  .section-header {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    color: var(--accent-color);
    margin-bottom: 20px;
    margin-top: 40px;
    text-transform: uppercase;

    .icon {
      margin-right: 8px;
    }
  }

  .container {
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    padding: 0 20px;

    @media (max-width: 600px) {
      margin: inherit;
    }
  }

  .game-modes-grid,
  .ecosystem-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;


  }

  // Game Mode Card Styles
  .game-mode-card,
  .ecosystem-card {
    background-color: var(--card-bg-color);
    border: 2px solid var(--accent-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px var(--shadow-color);
    overflow: hidden;
    transition: transform var(--transition-speed), box-shadow var(--transition-speed);
    cursor: pointer;
    display: flex;
    flex-direction: column;

    @media (max-width: 600px) {
      min-width: fit-content;
    }

    img {
      width: 100%;
      height: 300px; // Increased height for better visibility
      object-fit: cover;
      object-fit: fill;
    }

    h3 {
      font-size: 22px;
      margin: 15px;
      color: var(--primary-color);
    }

    p {
      font-size: 16px;
      margin: 0 15px 15px 15px;
      color: var(--secondary-color);
      line-height: 1.4;
    }

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 16px var(--shadow-color);
    }

    &.ecosystem-card {
      text-align: center;

      h3 {
        font-size: 20px;
        margin: 15px 0;
      }
    }

    .game-info {
      padding: 15px;
      border-top: 2px solid var(--accent-color);
      background-color: var(--background-color);
      color: var(--primary-color);
    }

    .game-attributes-scrollable {
      max-height: 200px;
      /* Set a max height to make the content scrollable */
      overflow-y: auto;
      /* Enable vertical scrolling */
      padding-right: 10px;
      /* Add padding to give space for the scrollbar */

      &::-webkit-scrollbar {
        width: 8px;
        /* Vertical scrollbar width */
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--accent-color);
        border-radius: 10px;
        /* Round scrollbar edges */
      }
    }

    .game-attributes {
      display: flex;
      flex-direction: column;
      /* Stack items vertically */
      gap: 10px;
      margin-top: 10px;
    }

    .attribute-item {
      background: var(--card-bg-color);
      padding: 10px;
      border-radius: 8px;
      text-align: left;
      box-shadow: 0 2px 4px var(--shadow-color);
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 4px 8px var(--shadow-color);
      }

      strong {
        color: var(--accent-color);
        font-weight: bold;
      }
    }
  }

  // Responsive Styles
  @media (max-width: 1024px) {

    .game-modes-grid,
    .ecosystem-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 600px) {

    .game-modes-grid,
    .ecosystem-grid {
      grid-template-columns: 1fr;

      grid-auto-flow: column;
      grid-auto-columns: max-content;
      overflow: scroll;

      grid-auto-flow: column;
      grid-auto-columns: max-content;
      grid-auto-rows: max-content;
      overflow: scroll;
      width: 216px;
      display: flex;
    }

    .game-selection-header {
      .header-container {
        flex-direction: column;
        align-items: flex-start;
      }

      .header-text {
        h1 {
          font-size: 24px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .section-header {
      font-size: 18px;
    }

    .game-mode-card img,
    .game-mode-card video,
    .ecosystem-card img {
      height: 200px;
    }

    .game-mode-card h3,
    .ecosystem-card h3 {
      font-size: 16px;
    }

    .game-mode-card p,
    .ecosystem-card p {
      font-size: 12px;
    }
  }

  @media (min-width: 1025px) and (max-width: 1366px),
  (min-width: 1367px) and (max-width: 1600px) {

    .game-modes-grid,
    .ecosystem-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (min-width: 1601px) and (max-width: 1920px),
  (min-width: 1921px) {

    .game-modes-grid,
    .ecosystem-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}