/* @import url("https://fonts.googleapis.com/css2?family=Michroma&display=swap");
@import url("http://fonts.cdnfonts.com/css/digital-numbers"); */

.back-to-the-future {
    margin: 0px !important;
    padding: 0px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: black;
}

.back-to-the-future.preview {
    scale: 0.65 0.8;
    /* position: absolute; */
    /* left: -141px; */
    left: 245px;
    top: 30px;
    /* transform: scale(0.65, 0.8); */

    align-self: center;
    height: 1px;
}

.back-to-the-future.preview-side {
    scale: 0.85 0.9;
    /* transform: scale(0.85, 0.9); */
    position: relative;
    left: -42px;
    top: 30px;
}

.slider {
    /* --item-width: 700px !important; */
    /* --item-width: 100% !important; */
}

.outer {
    /* min-height: 180px; */
    background: #505662;
    background: linear-gradient(74deg, #505662 13%, #98a3ba 24%, #505662 37%, #676f7e 47%, #3a3f47 62%, #626978 79%, #505662 100%);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 14px;
    border: 1px solid #000000;
    border-radius: 15px;
    -webkit-box-shadow: inset 3px 2px 3px rgba(255, 255, 255, 0.4), inset -2px -2px 2px rgba(0, 0, 0, 0.4);
    box-shadow: inset 3px 2px 3px rgba(255, 255, 255, 0.4), inset -2px -2px 2px rgba(0, 0, 0, 0.4);
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 10px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.header-label {
    background-color: #6d2424;
    color: rgba(233, 220, 220, 0.653);
    font-family: 'Michroma', sans-serif;
    line-height: 9px;
    font-size: 20px;
    font-weight: bold;
    padding: 6px 10px;
    border-top: 1px solid rgba(255, 208, 208, 0.435);
    -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.664);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.664);
    margin-bottom: 10px;
}

.header-label:nth-child(1) {
    margin-left: 40px;
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
}

.header-label:nth-child(2) {
    margin-left: 16px;
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
}

.header-label:nth-child(3) {
    margin-left: 40px;
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg);
}

.header-label:nth-child(4) {
    margin-left: 50px;
}

.header-label:nth-child(5) {
    margin-right: 30px;
}

#present .row .header-label:nth-child(1) {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
}

#present .row .header-label:nth-child(5) {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
}

.footer-label {
    background-color: black;
    color: rgba(233, 220, 220, 0.653);
    font-family: 'Michroma', sans-serif;
    line-height: 9px;
    font-size: 20px;
    font-weight: bold;
    padding: 8px 10px;
    border-top: 1px solid rgba(255, 208, 208, 0.435);
    -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.664);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.664);
    margin: 10px auto 0px auto;
}

.display {
    position: relative;
    background-color: #363636;
    border-radius: 5px;
    padding: 10px;
    height: 60px;
    -webkit-box-shadow: inset -3px 2px 15px 5px rgba(0, 0, 0, 0.85);
    box-shadow: inset -3px 2px 15px 5px rgba(0, 0, 0, 0.85);
    margin: 0px 16px;
    text-align: center;
    font-weight: lighter;
    font-family: 'Digital Numbers', sans-serif;
    letter-spacing: 3px;
    color: #ffdbe0;
    font-size: 46px;
    border-bottom: 2px solid #882828;
}

.display .time {
    position: relative;
    margin: 0px;
    padding: 0px;
    top: -10px;
    -webkit-animation: text-flicker-in-glow 0.1s linear infinite;
    animation: text-flicker-in-glow 0.1s linear infinite;
    z-index: 5;
    -webkit-transform: skewX(-10deg);
    transform: skewX(-10deg);
}

.display .number-back {
    position: absolute;
    color: #4e4e4e;
    top: -36px;
    top: 0px;
    z-index: 1;
    -webkit-transform: skewX(-10deg);
    transform: skewX(-10deg);
}

.display .destination {
    text-shadow: 0 0 1px #FFF, 0 0 10px white, 0 0 15px rgba(255, 255, 255, 0.418), 0 0 20px #f80707, 0 0 20px #f80707, 0 0 30px #f80707, 0 0 30px #f80707;
}

.display .present {
    text-shadow: 0 0 1px #FFF, 0 0 10px white, 0 0 15px rgba(255, 255, 255, 0.418), 0 0 20px #0c7b0c, 0 0 20px #0c7b0c, 0 0 30px #0c7b0c, 0 0 30px #0c7b0c;
}

.display .departed {
    text-shadow: 0 0 1px #FFF, 0 0 5px white, 0 0 15px rgba(255, 255, 255, 0.418), 0 0 20px #a86200, 0 0 20px #a86200, 0 0 20px #a86200;
}

#present .row .display {
    border-bottom: 2px solid #0c7b0c;
}

#departed .row .display {
    border-bottom: 2px solid #a86200;
}

.led-time-separator-container {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 15px;
}

.led-time-separator-container .led {
    margin: 8px 0px;
    margin: 10px 0px;

}

.led-unit-separator-container {
    position: relative;
}

.led-unit-separator-container .led:first-child {
    position: absolute;
    top: 20px;
    left: -7px;
    z-index: 5;
    -webkit-animation: blinky 1s step-start infinite;
    animation: blinky 1s step-start infinite;
}

.led-unit-separator-container .led:nth-child(2) {
    position: absolute;
    bottom: 20px;
    left: -7px;
    z-index: 5;
    -webkit-animation: blinky 1s step-start infinite;
    animation: blinky 1s step-start infinite;
}

.led-unit-separator-container .led:nth-child(3) {
    position: absolute;
    top: 20px;
    left: -7px;
    z-index: 1;
}

.led-unit-separator-container .led:nth-child(4) {
    position: absolute;
    bottom: 20px;
    left: -7px;
    z-index: 1;
}

.led {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: white;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.499);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.499);
}

.led-destination {
    background: #eb0000;
    background: radial-gradient(circle, #eb0000 2%, #a61010 51%, #280202 100%);
}

.led-destination-on {
    background: radial-gradient(circle, white 63%, #a61010 90%);
    -webkit-box-shadow: 0 0 2px #FFF, 0 0 4px rgba(255, 255, 255, 0.505), 0 0 6px #f80707, 0 0 10px #f80707, 0 0 10px #f80707;
    box-shadow: 0 0 2px #FFF, 0 0 4px rgba(255, 255, 255, 0.505), 0 0 6px #f80707, 0 0 10px #f80707, 0 0 10px #f80707;
}

.led-present {
    background: #0c7b0c;
    background: radial-gradient(circle, #15d815 2%, #074d07 51%, #280202 100%);
}

.led-present-on {
    background: radial-gradient(circle, white 63%, #0c7b0c 90%);
    -webkit-box-shadow: 0 0 2px #FFF, 0 0 4px rgba(255, 255, 255, 0.505), 0 0 6px #0c7b0c, 0 0 10px #0c7b0c, 0 0 10px #0c7b0c;
    box-shadow: 0 0 2px #FFF, 0 0 4px rgba(255, 255, 255, 0.505), 0 0 6px #0c7b0c, 0 0 10px #0c7b0c, 0 0 10px #0c7b0c;
}

.led-departed {
    background: black;
    background: radial-gradient(circle, #ff9b0f 2%, #754400, #280202 100%);
}

.led-departed-on {
    background: radial-gradient(circle, white 63%, #a86200 90%);
    -webkit-box-shadow: 0 0 2px #FFF, 0 0 4px rgba(255, 255, 255, 0.505), 0 0 6px #a86200, 0 0 10px #a86200, 0 0 10px #a86200;
    box-shadow: 0 0 2px #FFF, 0 0 4px rgba(255, 255, 255, 0.505), 0 0 6px #a86200, 0 0 10px #a86200, 0 0 10px #a86200;
}

.ampm-label {
    position: absolute;
    background-color: #6d2424;
    color: rgba(233, 220, 220, 0.653);
    font-family: 'Michroma', sans-serif;
    line-height: 9px;
    font-size: 12px;
    font-weight: bold;
    padding: 3px 3px 4px 3px;
    border-top: 1px solid rgba(255, 208, 208, 0.435);
    -webkit-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.664);
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.664);
}

.am-label {
    top: -12px;
    top: -5px;
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
}

.pm-label {
    bottom: 25px;
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
}

.month {
    letter-spacing: 14px;
}

/* animations */
@-webkit-keyframes text-flicker-in-glow {
    0% {
        opacity: 1;
    }

    30% {
        opacity: 0.9;
    }

    50% {
        opacity: 0.9;
    }

    70% {
        opacity: 1;
    }

    100% {
        opacity: 0.9;
    }
}

@keyframes text-flicker-in-glow {
    0% {
        opacity: 1;
    }

    30% {
        opacity: 0.9;
    }

    50% {
        opacity: 0.9;
    }

    70% {
        opacity: 1;
    }

    100% {
        opacity: 0.9;
    }
}

@-webkit-keyframes blinky {
    50% {
        opacity: 0;
    }
}

@keyframes blinky {
    50% {
        opacity: 0;
    }
}


@media only screen and (max-height: 350px) {
    .back-to-the-future.preview {
        scale: 0.47 0.6;
        /* position: absolute; */
        right: -223px;
    }
}

@media only screen and (max-width: 600px) {
    .back-to-the-future.preview {
        scale: 0.4 0.6;
        /* left: -222px;
        top: 0px; */
    }
}

@media only screen and (min-width: 700px) and (min-height: 1100px) {
    .back-to-the-future.preview {
        scale: 1 1.5;
        left: -222px;
        top: 0px;
    }
}

@media only screen and (max-device-width: 300px) {
    .back-to-the-future.preview {
        scale: 0.26 0.35;
    }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 780px) and (orientation: landscape) {
    .back-to-the-future.preview {
        scale: 0.65 0.52;
    }
}

@media only screen and (max-width: 72.6mm) {
    .back-to-the-future.preview {
        /* Adjust the size of the component */
        scale: 0.65 0.52;
        font-size: 14px;
        /* Example: Adjust the font size down */
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
only screen and (min--moz-device-pixel-ratio: 3),
only screen and (-o-min-device-pixel-ratio: 3/1),
only screen and (min-device-pixel-ratio: 3) {
    .back-to-the-future.preview {
        scale: 0.4 0.5;
    }
}

@media only screen and (max-width: 280px) and (max-height: 660px) {
    .back-to-the-future.preview {
        /* Adjust the size of the component */
        scale: 0.3 0.35;
        /* Example: Adjust the font size down */
    }
}