/* SCSS Styling for the Card Collection Component */
#ExploreUnReveledP2PCards {
  --background-color: #000;
  --primary-color: #fff;
  --accent-color: #FFD700;
  /* Gold Color for Highlights */
  --secondary-color: #adadad;
  /* Grey for Subtexts */
  --btn-hover-opacity: 0.8;
  --card-bg-color: rgba(30, 30, 30, 0.9);
  --shadow-color: rgba(255, 215, 0, 0.5);
  --transition-speed: 0.3s;
  --boost-color: #32CD32;


  height: 95vh;
  width: 100vw;
  font-family: 'Arial', sans-serif;
  background-color: #000000;
  margin: 0;
  overflow: hidden;

  /*Loader*/
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    font-size: 24px;
    color: #333;
  }

  .fade-in {
    animation: fadeInAnimation 1s ease-in-out;
  }

  @keyframes fadeInAnimation {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .visibility-toggle-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;

    .toggle-button {
      background-color: var(--accent-color);
      border: none;
      border-radius: 5px;
      color: #000;
      font-weight: bold;
      padding: 10px 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      transition: background-color 0.3s;

      &:hover {
        background-color: #ffc107;
      }

      i {
        font-size: 1.2rem;
      }

      .item-count {
        //position: absolute;
        top: 129px;
        right: 693px;
        background-color: #ff0000; // Red background for visibility
        color: #fff;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        pointer-events: none; // Prevents the badge from capturing click events
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); // Adds a subtle shadow for depth
        transition: background-color 0.3s, transform 0.3s;
      }
    }
  }

  .perspective-container {
    perspective: 1500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    /* padding: 20px; */
    height: 80vh;
    /* width: 300px; */
    overflow: hidden;
    overflow-y: auto;

    #Search {
      position: sticky;
      top: 0;
      z-index: 10;
      height: fit-content;
    }
  }

  .card-collection {
    display: contents;
  }

  .card-container {
    width: 300px;
    height: 500px;
    position: relative;
    transform-style: preserve-3d;
    cursor: pointer;
  }

  #modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.9);

    @media screen and (max-width: 600px) {
      overflow: auto;
      position: sticky;
      bottom: 0;
    }
  }

  .modal-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    padding: 3px;
    box-sizing: border-box;

    .close {
      background-color: var(--accent-color);
      border: none;
      border-radius: 5px;
      color: #000;
      font-weight: bold;
      padding: 10px 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      transition: background-color 0.3s;
    }

    @media screen and (max-width: 600px) {
      position: sticky;
      top: 0; // Keeps the content stuck to the top when scrolling on mobile
      margin: 0 auto;
    }
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 30px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
    z-index: 1001;
  }

  /* Reflective overlay on card face */
  .card-face::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle at var(--mouseX, 50%) var(--mouseY, 50%),
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0));
    transform: translateX(0) translateY(0);
    pointer-events: none;
    transition: opacity 0.2s ease-out;
    opacity: 0;
  }

  /* Disable the reflective overlay on expanded card */
  .rotatable-card .card-face::before {
    opacity: 0 !important;
  }

  .rotatable-card-container {
    perspective: 1500px;
    width: 100%;
    max-width: 400px;

    @media screen and (max-width: 600px) {
      width: 100%;
      height: auto;
      max-width: none;
      position: sticky;
      top: 20px; // The content will stick to the top with a slight offset for visibility.
    }
  }

  .rotatable-card {
    width: 90vw;
    max-width: 360px;
    height: calc(90vw * (5 / 2.5));
    max-height: 600px;
    position: relative;
    transform-style: preserve-3d;
    transform: rotateX(0deg) rotateY(0deg);
    transition: transform 2s ease-out;
    user-select: none;
    cursor: grab;
    margin: 0 auto;

    &:active {
      cursor: grabbing;
    }

    .card-face {
      border-radius: 15px;
      position: absolute;
    }

    .image-container {
      height: 300px;
    }

    .card-footer {
      margin-top: auto;
      margin-bottom: 20px;
    }

    .rarity,
    .tier {
      font-size: 18px;
    }
  }

  /* Styling for card colors in modal */
  .rotatable-card .card1 .rarity {
    background-color: #800080;
  }

  .rotatable-card .card1 .tier {
    background-color: #FFD700;
  }

  .rotatable-card .card2 .rarity {
    background-color: #FF4500;
  }

  .rotatable-card .card2 .tier {
    background-color: #C0C0C0;
  }

  .rotatable-card .card3 .rarity {
    background-color: #FFA500;
  }

  .rotatable-card .card3 .tier {
    background-color: #CD7F32;
  }

  .rotatable-card .card4 .rarity {
    background-color: #32CD32;
  }

  .rotatable-card .card4 .tier {
    background-color: #1E90FF;
  }

  @media (max-width: 480px) {
    #ExploreP2PCards .perspective-container {
      // height: calc(100vh - 18%);
    }
  }

  @media (max-height: 640px) {
    .P2PCard .card-face {
      height: 90%;
    }
  }

  ///Booset ::selection
  /* Booster Selection Section */
  .booster-selection-section {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    .section-header {
      margin: 4px;
    }

    .booster-deck {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;
      max-width: 900px;
    }

    .booster-card {
      position: relative;
      width: 200px;
      height: 250px;
      background-color: var(--card-bg-color);
      border: 2px solid var(--accent-color);
      border-radius: 15px;
      box-shadow: 0 4px 8px var(--shadow-color);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: transform var(--transition-speed), box-shadow var(--transition-speed), background-color var(--transition-speed);
      padding: 15px;
      text-align: center;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px var(--shadow-color);
        background-color: rgba(255, 215, 0, 0.1); // Light gold overlay on hover
      }

      .booster-quantity {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: #ff0000; // Red background for visibility
        color: #fff;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        pointer-events: none; // Prevents the badge from capturing click events
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); // Adds a subtle shadow for depth
        transition: background-color 0.3s, transform 0.3s;
      }

      .booster-selected-quantity {
        position: absolute;
        top: 10px;
        left: 10px;
        background-color: #0080ff; // Red background for visibility
        color: #fff;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        pointer-events: none; // Prevents the badge from capturing click events
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); // Adds a subtle shadow for depth
        transition: background-color 0.3s, transform 0.3s;
      }

      &[data-quantity="1"] .booster-quantity {
        background-color: #ff4500; // OrangeRed for low quantity
      }

      &[data-quantity="0"] .booster-quantity {
        background-color: #555; // Grey for no quantity
      }

      .booster-icon {
        font-size: 48px;
        color: var(--accent-color);
        margin-bottom: 15px;
      }

      .booster-name {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .booster-description {
        font-size: 14px;
        color: var(--secondary-color);
      }

      &.selected-booster {
        background-color: #32cd32; // Green background
        color: #fff; // White text/icons
        border: 3px solid #fff;
        transform: scale(1.05); // Slight enlargement to indicate selection
      }

      &.used-booster {
        background-color: #555; // Grey background to indicate usage
        color: #fff; // White text/icons
        cursor: not-allowed;
        opacity: 0.8; // Slight transparency to indicate disabled state

        .booster-icon {
          color: #fff; // White icon color
        }

        &:hover {
          background-color: #555; // Maintain grey background on hover
          transform: none; // Prevent scaling on hover
        }
      }
    }

    .confirm-booster-container {
      margin-top: 8px;
      text-align: center;

      #confirm-booster-button {
        padding: 12px 30px;
        font-size: 18px;
        background-color: var(--accent-color);
        border: none;
        border-radius: 25px;
        color: #000;
        font-weight: 700;
        cursor: pointer;
        transition: background-color var(--transition-speed), transform var(--transition-speed);

        &:disabled {
          background-color: #555;
          cursor: not-allowed;
        }

        &:hover:not(:disabled) {
          background-color: #ffc107;
          transform: scale(1.05);
        }
      }
    }
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    .booster-selection-section {
      .booster-deck {
        flex-direction: column;
        align-items: center;
        gap: 15px; // Reduce gap between booster cards
      }

      .booster-card {
        width: 90%; // Reduce width to fit better on smaller screens
        height: auto; // Allow height to adjust based on content
        padding: 10px; // Reduce padding

        .booster-quantity {
          width: 20px;
          height: 20px;
          font-size: 12px;
          top: 8px;
          right: 8px;
        }

        .booster-selected-quantity {
          width: 20px;
          height: 20px;
          font-size: 12px;
          top: 8px;
          left: 8px;
        }

        .booster-icon {
          font-size: 36px; // Smaller icon size
          max-width: 80px; // Limit icon width
        }

        .booster-name {
          font-size: 18px; // Slightly smaller font
        }

        .booster-description {
          font-size: 14px; // Slightly smaller font
        }
      }

      #confirm-booster-button {
        padding: 10px 25px;
        font-size: 16px;
      }
    }
  }

  @media (max-width: 480px) {
    .booster-selection-section {
      padding: 8px;

      .section-header {
        font-size: 23px;
      }

      .booster-deck {
        gap: 10px; // Further reduce gap
        flex-wrap: nowrap;
        justify-content: normal;
        overflow: scroll;
        height: 83%;

        .booster-card {
          &.selected-booster {
            transform: none; // Slight enlargement to indicate selection
          }
        }
      }

      .booster-card {
        width: 100%; // Full width on very small screens
        padding: 8px; // Further reduce padding

        .booster-quantity {
          width: 18px;
          height: 18px;
          font-size: 10px;
          top: 6px;
          right: 6px;
        }

        .booster-selected-quantity {
          width: 18px;
          height: 18px;
          font-size: 10px;
          top: 6px;
          left: 6px;
        }

        .booster-icon {
          font-size: 28px; // Smaller icon size
          max-width: 60px; // Limit icon width
        }

        .booster-name {
          font-size: 16px; // Smaller font
        }

        .booster-description {
          font-size: 12px; // Smaller font
        }
      }

      #confirm-booster-button {
        padding: 8px 20px;
        font-size: 14px;
      }
    }
  }
}