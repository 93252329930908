.app {
  @import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

  :root {
    font-size: 16px;
    color: white;
    font-family: "Montserrat", sans-serif;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  div {
    display: -webkit-box;
    display: flex;
  }

  button {
    border: none;
    background: none;
    outline: none;
    color: white;
    cursor: pointer;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .banner {
    position: relative;
    height: 100vh;
    // background: -webkit-gradient(linear,
    //         right top,
    //         left top,
    //         from(#8942a8),
    //         to(#ba382f));
    opacity: 75%;
    // background: linear-gradient(to left, #8942a8, #ba382f);
    overflow: hidden;

    .stock-limit {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border-radius: 20px;
      background-color: #010101;
      width: 400px;
      height: 180px;
      flex-direction: column;
      display: none;

      em {
        position: absolute;
        width: 80%;
        top: 20%;
        left: 0;
        right: 0;
        margin: 0 auto;
        line-height: 1.5rem;
        text-align: center;
        font-weight: 600;
        font-size: 1.2rem;
      }
    }
  }

  .banner .cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);

    backdrop-filter: blur(5px);
    display: none;
  }

  .box-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    overflow: hidden;
  }

  .box-area li {
    position: absolute;
    display: block;
    list-style: none;
    width: 25px;
    height: 25px;
    background: white;
    overflow: hidden;
    -webkit-animation: animation 20s linear infinite;
    animation: animate 20s linear infinite;
    bottom: -120px;
  }

  .box-area li:nth-child(1) {
    left: 86%;
    width: 80px;
    height: 80px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  .box-area li:nth-child(2) {
    left: 12%;
    width: 30px;
    height: 30px;
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
  }

  .box-area li:nth-child(3) {
    left: 70%;
    width: 100px;
    height: 100px;
    -webkit-animation-delay: 5.5s;
    animation-delay: 5.5s;
  }

  .box-area li:nth-child(4) {
    left: 42%;
    width: 150px;
    height: 150px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-duration: 15s;
    animation-duration: 15s;
  }

  .box-area li:nth-child(5) {
    left: 65%;
    width: 40px;
    height: 40px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  .box-area li:nth-child(6) {
    left: 15%;
    width: 110px;
    height: 110px;
    -webkit-animation-delay: 3.5s;
    animation-delay: 3.5s;
    -webkit-animation-duration: 15s;
    animation-duration: 15s;
  }

  @-webkit-keyframes animate {
    0% {
      -webkit-transform: translateY(0) rotate(0deg);
      transform: translateY(0) rotate(0deg);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(-100vh) rotate(360deg);
      transform: translateY(-100vh) rotate(360deg);
      opacity: 0;
    }
  }

  @keyframes animate {
    0% {
      -webkit-transform: translateY(0) rotate(0deg);
      transform: translateY(0) rotate(0deg);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(-100vh) rotate(360deg);
      transform: translateY(-100vh) rotate(360deg);
      opacity: 0;
    }
  }

  .fa-shopping-cart {
    position: absolute;
    right: 2rem;
    top: 1.2rem;
    color: black;
  }

  .fa-shopping-cart:hover {
    color: white;
  }

  .total-qty {
    position: absolute;
    height: 1rem;
    width: 1rem;
    background: white;
    color: black;
    border-radius: 50%;
    border: 1px solid white;
    text-align: center;
    line-height: 1rem;
    font-weight: 600;
    top: 1rem;
    right: 1.5rem;

    user-select: none;
  }

  .main-cart {
    position: absolute;
    -webkit-box-pack: space-evenly;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 90%;
    height: 100%;
    margin-left: 5%;
    align-self: center;
    -webkit-box-align: center;
    align-items: center;

    .message {
      color: floralwhite;
    }
  }

  .dapp_card {
    position: relative;
    flex-shrink: 0;
    height: 300px;
    width: 200px;
    background-color: #010101;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 7px 5px -5px #4d4b4b;
    -webkit-transition: 0.5s;
    transition: 0.5s;

    .img-container {
      position: absolute;
      height: 185px;
      width: 185px;
      margin: 0 auto;
      left: 0;
      right: 0;
      transform: translateY(35px);

      img {
        width: 100%;
        height: 100%;
        align-self: center;
      }

      .out-of-stock-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.25);

        justify-content: center;
        align-items: center;
        display: none;

        span {
          font-weight: 900;
          color: rgba(255, 200, 200, 0.75);
          font-size: 1.5rem;
        }
      }
    }
  }

  .top-bar {
    transform: translateY(0.5rem);
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
    padding: 0 1rem;

    .fa-apple {
      font-size: 1.5rem;
    }

    .stocks {
      font-size: 0.8rem;
      color: lightgreen;
    }
  }

  .dapp_card .details {
    position: absolute;
    height: 220px;
    width: 100%;
    background-color: #2e2f33;
    border-radius: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 20px;
    bottom: -165px;
    -webkit-transition: 0.25s;
    transition: 0.25s;
    color: white;
  }

  .dapp_card .details .name-fav {
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .dapp_card .details .name-fav strong {
    font-weight: 600;
  }

  .fav {
    color: red;
  }

  .dapp_card .details .wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    display: none;
  }

  .details .wrapper p {
    font-size: 0.9rem;
    padding: 5px 0;
    font-weight: light;
  }

  .dapp_card .details .purchase {
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
  }

  .dapp_card .details .purchase p {
    font-weight: 500;
  }

  .details .purchase .add-btn {
    border-radius: 20px;
    border: 1px solid white;
    background: none;
    color: white;
    padding: 6px 10px;
  }

  .details .purchase .add-btn:hover {
    background: white;
    color: black;
  }

  .dapp_card .details:hover {
    height: 60%;
  }

  .main-cart .dapp_card:hover {
    height: 450px;
    width: 260px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }

  .main-cart .dapp_card:hover .details {
    bottom: 0;
  }

  .main-cart .dapp_card:hover .wrapper {
    display: block;
  }

  .side-nav {
    position: absolute;
    background: #010101;
    opacity: 0.95;
    height: 100%;
    width: 60%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    top: 0;
    right: -100%;
    z-index: 10;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    color: floralwhite;
  }

  .side-nav.show {
    right: 0px;
  }

  .side-nav .fa-times {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 1.5rem;
  }

  .side-nav h2 {
    align-self: center;
    -webkit-transform: translateY(1rem);
    transform: translateY(1rem);
    font-size: 2rem;
    font-weight: 600;
  }

  .side-nav .cart-items {
    position: absolute;
    width: 90%;
    margin: 0 5%;
    height: 50%;
    top: 10%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;

    .empty-cart {
      margin: auto 0;
      font-size: 2rem;
      font-weight: 900;
      text-align: center;
      color: rgba(255, 255, 255, 0.25);
    }
  }

  .side-nav .cart-items .cart-item {
    color: white;
    height: 10rem;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid whitesmoke;

    &:hover {
      background: rgba(255, 255, 255, 0.25);
    }

    .cart-img {
      width: 10rem;
      height: 10rem;
      justify-content: center;

      img {
        width: 100%;
        height: 90%;
        align-self: center;
      }
    }

    strong.name {
      max-width: 80px;
      min-width: 80px;
    }
  }

  .side-nav .final {
    position: relative;
    align-self: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    // bottom: 10%;
  }

  .side-nav .final strong {
    align-self: flex-end;
    font-size: 1.2rem;
    font-weight: 600;
    margin-right: 20px;
    margin-bottom: 1rem;
  }

  .side-nav .final .action {
    -webkit-box-pack: space-evenly;
    justify-content: space-evenly;
  }

  .side-nav .final .action .btn {
    font-size: 1.2rem;
    padding: 1rem 2rem;
    border: 1px solid white;
    margin-right: 20px;
    border-radius: 10px;

    @media (max-width: 576px) {
      padding: 0px;
    }
  }

  .side-nav .final .action .btn:hover {
    color: black;
    box-shadow: inset 20em 0 0 0 white;
  }


  .qty-change {
    -webkit-box-pack: space-evenly;
    justify-content: space-evenly;
    -webkit-box-align: baseline;
    align-items: baseline;
  }

  .btn-qty {
    border: 1px solid white;
    border-radius: 50%;
    padding: 2px 5px;

    &:hover {
      background: white;
      color: black;
    }
  }

  .qty {
    padding: 2px 5px;
  }

  .invoice {
    position: absolute;
    height: 90%;
    width: 90%;
    background: rgba(0, 0, 0, 0.95);
    padding: 5% 4%;
    margin: 0 auto;
    left: 0;
    right: 0;
    align-self: center;
    flex-direction: column;
    border-radius: 20px;
    transition: 0.25s;

    .shipping-items {
      padding: 10px 0;
      width: 100%;
      justify-content: space-between;

      .item-names,
      .items-price {
        flex-direction: column;
        justify-content: space-around;
      }
    }

    .payment {
      flex-direction: column;

      em,
      div {
        margin-top: 10px;
        font-weight: 600;
        justify-content: space-between;
      }
    }

    .order {
      position: absolute;
      bottom: 10%;
      width: 40%;
      justify-content: space-around;

      .btn {
        padding: 1rem 2rem;
        border-radius: 10px;
        font-size: 1.2rem;
      }

      .btn-order {
        background: #00bbf9;
      }

      .btn-cancel {
        background: #bb342f;
      }
    }

    .order-details {
      height: 300px;
      flex-direction: column;
      justify-content: space-around;

      em,
      p {
        border-bottom: 1px dashed white;
        padding-bottom: 10px;
      }

      em {
        font-size: 1.2rem;
        font-weight: 600;
      }

      .thanks {
        position: absolute;
        text-align: center;
        margin: auto;
        border: none;
        left: 0;
        right: 0;
        bottom: 55%;
      }

      p {
        font-size: 1.1rem;

        span {
          font-weight: 600;
        }
      }
    }
  }

  .btn-ok {
    position: absolute;
    width: 80%;
    font-size: 1.2rem;
    border-radius: 10px;
    padding: 1rem 2rem;
    margin: auto;
    bottom: 10%;
    left: 0;
    right: 0;
    background: #5bc0be;
  }

  @media (max-width: 768px) {
    .side-nav {
      width: 80%;
    }

    .invoice .order {
      width: 60%;
    }

    .main-cart {
      overflow: scroll;
    }
  }

  @media (max-width: 576px) {
    .side-nav {
      width: 100%;

      .cart-img {
        display: none;
      }

      .empty-cart {
        padding: 10px;
      }
    }

    .invoice .order {
      width: 80%;
    }

    .nav {
      width: 576px;
      width: -webkit-fill-available;
      height: 4rem;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(5px);
      filter: invert(1);
    }

    .main-cart {
      justify-content: flex-start;
      flex-wrap: nowrap;
      flex-direction: column;
      height: 90vh;
      top: 4rem;

      .dapp_card {
        margin: 20px auto;
      }
    }
  }
}

.main {
  @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&family=Roboto:wght@300;400;500;700&display=swap");

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  nav {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
  }

  nav ul,
  nav ul li {
    outline: 0;
  }

  nav ul li a {
    text-decoration: none;
  }

  img {
    width: 100%;
  }

  h1 {
    font-size: clamp(1.2rem, 3vw, 1.5rem);
  }

  body {
    font-family: "Nunito", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-image: url(https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/aa462558-0106-4268-9864-d34a4f35531f);
    background-repeat: no-repeat;
    background-size: cover;
  }

  /* MAIN MENU */

  main {
    display: grid;
    grid-template-columns: 11% 89%;
    width: 95%;
    margin: 40px;
    background: rgba(16, 21, 61, 0.5);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 15px;
    box-shadow: 0 0.5px 0 1px rgba(255, 255, 255, 0.23) inset,
      0 1px 0 0 rgba(255, 255, 255, 0.6) inset, 0 4px 16px rgba(0, 0, 0, 0.12);
    z-index: 10;
  }

  .main-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 15px 0 0 15px;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    padding: 12px 0 20px;
    overflow: hidden;
    font-family: inherit;
  }

  .user-info img {
    padding: 12px 24px 6px;
    border-radius: 50%;
  }

  .user-info p {
    color: #fff;
    font-size: clamp(0.8rem, 3vw, 1rem);
    font-weight: 500;
    text-align: center;
    line-height: 1;
    padding: 0 6px 32px;
  }

  .nav-item {
    display: block;
  }

  .nav-item a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    padding: 12px 0;
    margin: 0 8px;
    border-radius: 5px;
  }

  .nav-item.active a {
    background: rgba(106, 109, 155, 0.5);
    text-decoration: none;
  }

  .nav-icon {
    width: 40px;
    height: 20px;
    font-size: 1.1rem;
  }

  .nav-text {
    display: block;
    width: 70px;
    height: 20px;
    letter-spacing: 0;
  }

  /* CONTENT */

  .content {
    display: grid;
    grid-template-columns: 75% 25%;
  }

  /* LEFT CONTENT */

  .left-content {
    padding: 30px 20px;
    color: #e5e5e5;
  }

  /* SLIDER */

  .slider-container {
    margin: 0 auto;
    width: 100%;
  }

  .swiper {
    width: 100%;
    padding: 16px 0 20px;
    margin-bottom: 50px;
  }

  .swiper-slide {
    position: relative;
    width: 600px;
  }

  .swiper-slide img {
    border-radius: 20px;
    height: 300px;
    object-fit: cover;
    border: 2px solid rgba(159, 160, 168, 0.5);
  }

  .swiper-pagination {
    --swiper-pagination-bottom: -4px;
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: #ffffffe7;
    transition: all 0.3s ease-in-out;
  }

  .swiper-pagination-bullet-active {
    background-color: #fff;
    width: 18px;
    border-radius: 8px;
  }

  .slide-overlay {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    row-gap: 12px;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 98%;
    background: linear-gradient(240deg,
        transparent,
        transparent,
        rgba(38, 21, 149, 0.8));
    border-radius: 20px;
    padding: 30px;
  }

  .slide-overlay h2 {
    font-size: clamp(1.2rem, 3vw, 2.2rem);
    font-weight: 700;
  }

  .slide-overlay button {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    width: max-content;
    padding: 12px 16px;
    background: #e9e6eb2a;
    color: #e8e5e5;
    border: 1px solid rgba(159, 160, 168, 0.4);
    border-radius: 12px;
    outline: 0;
    font-size: clamp(0.7rem, 3vw, 1rem);
    font-weight: 500;
    text-transform: uppercase;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    transition: all 0.4s linear;
    cursor: pointer;
  }

  .slide-overlay button:is(:hover, :focus-visible) {
    background: #e9e6eb;
    color: #000;
  }

  /* Containers of Artist and Albums */

  .containers {
    display: flex;
    align-items: center;
    padding: 0 0 12px;
    overflow-x: auto;
    cursor: grab;
  }

  /* ARTISTS */

  .artists h1 {
    margin-bottom: 24px;
  }

  .artist-container {
    column-gap: 20px;
  }

  .artist {
    display: grid;
    grid-auto-flow: dense;
    align-items: center;
    grid-template-rows: 8fr 2fr;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
  }

  .artist img {
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid rgba(169, 150, 253, 0.5);
    width: 140px;
    aspect-ratio: 1/1;
    margin-bottom: 8px;
    box-shadow: rgba(221, 221, 221, 0.3) 0px 6px 18px -3px,
      rgba(221, 221, 221, 0.2) 0px -3px 0px inset;
    transition: all 0.2s;
  }

  .artist img:hover {
    border: 4px solid rgba(169, 150, 253, 0.6);
  }

  .artist p {
    font-size: clamp(0.9rem, 3vw, 1rem);
    font-weight: 500;
    text-align: center;
  }

  /* ALBUMS */

  .albums h1 {
    margin: 60px 0 14px;
  }

  .album-container {
    column-gap: 24px;
  }

  .album {
    display: grid;
    grid-auto-flow: dense;
    grid-template-rows: 5fr 2fr;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
  }

  .album-frame {
    position: relative;
    width: 180px;
    aspect-ratio: 1/1;
    border: 2px solid rgba(169, 150, 253, 0.5);
    border-radius: 10px;
    box-shadow: rgba(221, 221, 221, 0.3) 0px 8px 18px -3px,
      rgba(221, 221, 221, 0.2) 0px -3px 0px inset;
    margin-bottom: 15px;
    overflow: hidden;
  }

  .album-frame img {
    position: absolute;
    inset: 0;
    height: 100%;
    object-fit: cover;
    transition: transform 0.8s;
  }

  .album-frame img:hover {
    transform: rotate(3deg) scale(1.2);
  }

  .album h2 {
    font-size: clamp(0.9rem, 4vw, 1.1rem);
    font-weight: 500;
    line-height: 1.3;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .album p {
    font-size: clamp(0.9rem, 4vw, 1rem);
    opacity: 0.5;
  }

  /* Containers Scrollbar Style */

  .artist-container::-webkit-scrollbar,
  .album-container::-webkit-scrollbar {
    height: 10px;
  }

  .artist-container::-webkit-scrollbar-track,
  .album-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.3rem rgb(79, 78, 78);
    border-radius: 40px;
  }

  .artist-container::-webkit-scrollbar-thumb,
  .album-container::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0.5rem rgb(116, 116, 116);
    background-color: rgba(25, 43, 206, 0.2);
    outline: none;
    border-radius: 40px;
  }

  /* RIGHT CONTENT */

  .right-content {
    display: grid;
    grid-template-rows: 60% 40%;
    border-radius: 0 15px 15px 0;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    padding: 30px 20px;
    color: #e5e5e5;
  }

  /* SONGS */

  .recommended-songs h1 {
    margin-bottom: 24px;
  }

  .song-container {
    align-items: center;
  }

  .song {
    display: grid;
    grid-template-columns: 26% auto 10%;
    align-items: center;
    margin-bottom: 16px;
  }

  .song-img {
    position: relative;
    width: 60px;
    border-radius: 6px;
  }

  .song-img img {
    aspect-ratio: 4/3;
    border-radius: inherit;
    object-fit: cover;
    border: 2px solid rgba(159, 160, 168, 0.5);
    box-shadow: rgba(221, 221, 221, 0.3) 0px 6px 18px -3px,
      rgba(221, 221, 221, 0.2) 0px -3px 0px inset;
  }

  .song-img .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 92%;
    background-color: rgba(169, 150, 253, 0.6);
    border-radius: inherit;
    font-size: 1.75rem;
    opacity: 0;
    transition: all 0.4s linear;
    cursor: pointer;
  }

  .song-img:hover .overlay {
    opacity: 1;
  }

  .song h2 {
    font-size: 1rem;
  }

  .song p,
  .song span {
    font-size: 0.8rem;
    font-weight: 300;
  }

  .song p {
    opacity: 0.8;
  }

  /* MUSIC PLAYER */

  .music-player {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: rgba(188, 184, 198, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: inset 2px -2px 6px rgba(214, 214, 214, 0.2),
      inset -3px 3px 3px rgba(255, 255, 255, 0.3);
    border-radius: 16px;
    padding: 30px 20px;
    margin-top: 20px;
  }

  .album-cover {
    position: relative;
  }

  .album-cover img {
    border-radius: 50%;
    border: 2px solid rgba(222, 215, 255, 0.9);
    max-width: 120px;
    aspect-ratio: 1/1;
    object-fit: cover;
    box-shadow: 0 10px 60px rgba(200, 187, 255, 1.75);
    transition: transform 0.5s ease-out;
    pointer-events: none;
    user-select: none;
  }

  .point {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 16px;
    background-color: rgba(17, 6, 58, 0.7);
    border: 2px solid rgba(222, 215, 255, 0.9);
    aspect-ratio: 1/1;
    border-radius: 50%;
    z-index: 2;
  }

  .music-player h2 {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 16px 0 2px;
  }

  .music-player p {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 26px;
    opacity: 0.8;
  }

  /* Music Player Controls */

  #progress {
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: 6px;
    background: rgba(200, 187, 255, 0.6);
    border-radius: 4px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  #progress::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    background: rgb(77, 58, 162);
    width: 20px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 4px solid rgb(234, 229, 255);
    box-shadow: 0 6px 10px rgba(200, 187, 255, 0.4);
  }

  .controls {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .controls button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    aspect-ratio: 1/1;
    margin: 20px;
    background: rgba(200, 187, 255, 0.6);
    border-radius: 50%;
    border: 0;
    outline: 0;
    color: #fff;
    font-size: 1.1rem;
    box-shadow: 0 4px 8px rgba(200, 187, 255, 0.3);
    cursor: pointer;
    transition: all 0.3s linear;
  }

  .controls button:is(:hover, :focus-visible) {
    transform: scale(0.96);
  }

  .controls button:nth-child(2) {
    transform: scale(1.3);
  }

  .controls button:nth-child(2):is(:hover, :focus-visible) {
    transform: scale(1.25);
  }

  /* MEDIA QUERIES */

  @media (max-width: 1500px) {
    main {
      grid-template-columns: 6% 94%;
    }

    .user-info img {
      border-radius: 50%;
      padding: 12px 12px 6px;
    }

    .nav-icon {
      text-align: center;
      transform: translateY(2px);
    }

    .nav-text {
      display: none;
    }
  }

  @media (max-width: 1310px) {
    main {
      grid-template-columns: 8% 92%;
      margin: 30px;
    }
  }

  @media (max-width: 1250px) {
    .swiper-slide {
      width: 500px;
    }

    .swiper-slide img {
      border-radius: 16px;
      height: 280px;
    }

    .artist {
      grid-template-rows: 7fr 2fr;
    }

    .artist img {
      width: 120px;
    }

    .album-frame {
      width: 160px;
    }

    .song {
      grid-template-columns: 29% auto 10%;
    }

    .controls button {
      margin: 15px;
    }
  }

  @media (max-width: 1100px) {
    .content {
      grid-template-columns: 60% 40%;
    }

    .left-content {
      padding: 40px 20px 20px;
    }

    .swiper-slide {
      width: 410px;
    }

    .swiper-slide img {
      height: 220px;
    }

    .artist {
      grid-template-rows: 5fr 2fr;
    }

    .artist img {
      width: 90px;
    }

    .album {
      grid-template-rows: 4fr 2fr;
    }

    .album-frame {
      width: 130px;
    }

    .song {
      grid-template-columns: 26% auto 10%;
    }

    .song:nth-child(8),
    .song:nth-child(9) {
      display: none;
    }
  }

  @media (max-width: 910px) {
    main {
      grid-template-columns: 10% 90%;
      margin: 20px;
    }

    .left-content {
      padding: 30px 20px 20px;
    }

    .swiper-slide {
      width: 350px;
    }

    .swiper-slide img {
      height: 180px;
    }

    .artist {
      grid-template-rows: 4fr 2fr;
    }

    .artist img {
      width: 80px;
    }

    .album {
      grid-template-rows: 3fr 2fr;
    }

    .album-frame {
      width: 100px;
    }

    .right-content {
      grid-template-rows: 55% 45%;
    }

    .song {
      grid-template-columns: 30% auto 12%;
    }

    .song:nth-child(7) {
      display: none;
    }
  }

  @media (max-width: 825px) {
    .content {
      grid-template-columns: 52% 48%;
    }

    .swiper-slide {
      width: 320px;
    }

    .swiper-slide img {
      height: 200px;
    }

    .slide-overlay {
      row-gap: 8px;
      padding: 12px 36px;
    }

    .slide-overlay button {
      padding: 8px 12px;
    }

    .song {
      grid-template-columns: 28% auto 10%;
    }
  }

  @media (max-width: 700px) {
    main {
      grid-template-columns: 15% 85%;
    }

    .content {
      grid-template-columns: 100%;
      grid-template-areas:
        "leftContent"
        "rightContent";
    }

    .left-content {
      grid-area: leftContent;
    }

    .slide-overlay {
      row-gap: 12px;
      padding: 20px 30px;
    }

    .swiper-slide {
      width: 420px;
    }

    .artist img {
      width: 110px;
    }

    .album {
      grid-template-rows: 3fr 2fr;
    }

    .album-frame {
      width: 140px;
    }

    .right-content {
      grid-area: rightContent;
      border-left: unset;
      grid-template-rows: 60% 40%;
      row-gap: 16px;
    }

    .song {
      grid-template-columns: 18% auto 8%;
    }

    .song:nth-child(7),
    .song:nth-child(8),
    .song:nth-child(9) {
      display: grid;
    }

    .controls button {
      margin: 20px;
    }
  }

  @media (max-width: 580px) {
    .swiper-slide {
      width: 340px;
    }

    .swiper-slide img {
      height: 180px;
    }

    .artist img {
      width: 80px;
    }

    .album {
      grid-template-rows: 3fr 2fr;
    }

    .album-frame {
      width: 100px;
    }

    .song {
      grid-template-columns: 23% auto 8%;
    }
  }

  @media (max-width: 450px) {
    .user-info img {
      border-radius: 50%;
      padding: 6px 6px 2px;
    }

    .swiper-slide {
      width: 320px;
    }

    .slide-overlay {
      row-gap: 8px;
      padding: 12px 36px;
    }

    .song {
      grid-template-columns: 25% auto 8%;
    }
  }
}