.father {
    margin: auto;
    width: 100%;
    /* This makes the father take up the full width of the page */
    height: 100%;
    position: relative;
    transition: all .5s ease-in-out;
}

.father>div {
    text-align: center;
    line-height: 300px;
    transition: all .5s ease-in-out;
    overflow: scroll;

    /* border-radius:5px; */
}

.left {
    float: left;
    width: 20%;
    /* This is a placeholder, adjust according to your design */
    height: 100vh;
    background: black;

    /* show the left pannel  */
    /* display: inline-block; */
    /* width:80%; */
}

.left * {
    /* display: none; */
    visibility: hidden;
}

.left:hover {
    width: 80%;
    /* This is a placeholder, adjust according to your design */
    /* height:600px; */
}

.left:hover * {
    /* display: inline-block; */
    visibility: visible;
}

.left:hover~.right * {
    display: none;
}

.left:hover~.right {
    width: 20%;
    /* This is a placeholder, adjust according to your design */
    /* height:600px;  */
}

.left:hover~.right span {
    /* display:inline-block; */
}

.left:hover span {
    /* display: none; */
}

.right {
    float: right;
    /* This is a placeholder, adjust according to your design */
    width: 80%;
    height: 100vh;
    background: white;
}

.right span {
    display: none;
    transform: rotate(-90deg);
}

.left span {
    /* display: inline-block; */
    /* transform: rotate(-90deg); */
}

.game-list img {
    width: 100%;
    height: 22rem;
}