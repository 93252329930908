*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: #eceff1;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "DM Sans", system-ui, sans-serif;
  color: #263238;
}

.card {
  width: 22rem;
  max-width: 90%;
  border: solid 4px #fff;
  border-radius: 2rem;
  padding: 0.75rem;
  text-decoration: none;
  color: inherit;
  background: #eceff1;
  box-shadow: 0 16px 32px #cfd8dc;
  transition: all 400ms ease;
  // z-index: 1;
  &.card1 {
    background: radial-gradient(black, transparent);
    .card {
      &__body {
        background: #fff;
        background: radial-gradient(black, transparent);
      }
      &__body:hover {
        background: repeating-linear-gradient(45deg, black, transparent 100px);
      }
    }
  }
  &.card2 {
    background: radial-gradient(#406b81, transparent);
    .card {
      &__body {
        background: #fff;
        background: radial-gradient(#406b81, transparent);
      }
      &__body:hover {
        background: repeating-radial-gradient(#406b81, transparent 100px);
      }
    }
  }
  &.card3 {
    background: radial-gradient(#1688bc, transparent);
    .card {
      &__body {
        background: #b0bec5;
        background: radial-gradient(#1688bc, transparent);
      }
      &__body:hover {
        background: repeating-linear-gradient(52deg, skyblue, transparent 64px);
      }
    }
  }
  &.card4 {
    background: radial-gradient(#68828e, transparent);
    .card {
      &__body {
        background: #b0bec5;
        background: radial-gradient(#68828e, transparent);
      }
      &__body:hover {
        background: repeating-linear-gradient(52deg, skyblue, transparent 64px);
      }
    }
  }
  &:hover {
    background: #fff;
  }
  &__body {
    border-radius: 1.25rem;
    padding: 0.75rem;
  }

  &__top {
    display: flex;
    font-weight: 500;
  }
  &__owner,
  &__creator {
    width: 50%;
    display: flex;
  }
  &__owner {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 28px;
      width: 2px;
      height: 100%;
      border-radius: 2px;
      background: #eceff1;
    }
  }
  &__creator {
    flex-direction: row-reverse;
    text-align: right;
  }
  &__avatar {
    // display: flex;
    width: 3rem;
    height: 3rem;
    background: #eceff1;
    border-radius: 100%;
    flex-shrink: 0;

    padding: 17px;
    text-align: center;
  }
  // &__tier {
  //   position: absolute;
  //   top: 5px;
  //   left: -33px;
  //   width: 127px;
  // }
  &__user {
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__title {
      font-size: 0.75rem;
      // color: #B0BEC5;
      color: ghostwhite;
    }
  }
  &__image {
    padding-top: 75%;
    position: relative;
    overflow: hidden;
    margin-top: 0.75rem;
    border-radius: 1rem;
    background: #eceff1;
    box-shadow: 0 16px 32px #eceff1;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__info {
    margin: 0.75rem;
    display: flex;
    justify-content: space-between;
    p {
      margin: 0;
    }
  }
  &__footer {
    background: #fff;
    padding: 0.75rem;
    border-radius: 0.75rem 0.75rem 1.75rem 1.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__btn {
    width: 48%;
    padding: 1rem;
    text-align: center;
    font-weight: 500;
    transition: all 200ms ease;
    &--primary {
      background: #263238;
      color: #eceff1;
      border-radius: 0.5rem 0.5rem 1.25rem 0.5rem;
      &:hover {
        background: #304ffe;
      }
    }
    &--secondary {
      border-radius: 0.5rem 0.5rem 0.5rem 1.25rem;
      &:hover {
        background: #eceff1;
        color: #304ffe;
      }
    }
  }

  .tier {
    position: absolute;
    top: 5px;
    left: -33px;
    width: 127px;
  }
}
