#Search {
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

  --primary-color: #1DB954;
  /* Vert moderne */
  --secondary-color: #121212;
  /* Fond sombre */
  --accent-color: #535353;
  /* Gris foncé */
  --text-color: #FFFFFF;
  --background-color: #191414;
  /* Fond légèrement plus clair */

  font-family: 'Roboto',
    sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--background-color);
  color: var(--text-color);
  width: 100%;

  .header {
    background-color: var(--secondary-color);
    padding: 50px 20px;
    text-align: center;
    position: relative;
  }

  h1 {
    margin: 0;
    font-size: 42px;
    font-weight: 700;
  }

  .subtitle {
    font-size: 20px;
    margin-top: 10px;
    font-weight: 400;
    color: var(--primary-color);
  }

  .toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: var(--secondary-color);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }

  .search-container {
    display: flex;
    align-items: center;
    gap: 15px;
    max-width: 800px;
    width: 100%;
    justify-content: center;
  }

  .dropdown .button-container {
    display: flex;
    align-items: stretch;
    gap: 15px;

    button {
      width: 100%;
    }
  }

  .dropdown button {
    padding: 12px 24px;
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    transition: background-color 0.3s ease;
  }

  input[type="text"],
  input[type="date"],
  input[type="number"],
  select {
    padding: 12px;
    border: 1px solid var(--accent-color);
    border-radius: 5px;
    font-size: 16px;
    background-color: var(--secondary-color);
    color: var(--text-color);
    flex-grow: 1;
  }

  input::placeholder {
    color: var(--accent-color);
  }

  #searchInput {
    padding-left: 20px;
    flex-grow: 2;
  }

  button {
    padding: 12px 24px;
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    transition: background-color 0.3s ease;
  }

  button:hover {
    background-color: var(--accent-color);
  }

  .dropdown {
    position: relative;
    display: inline-grid;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    background-color: var(--secondary-color);
    min-width: 250px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    z-index: 1;
    padding: 20px;
    border-radius: 5px;
  }

  .dropdown-content.active {
    display: block;
    height: calc(100vh - 6rem);
    overflow: auto;
  }

  #settingsContent {
    .select-all-toggle {
      justify-self: right;

      // justify-self: center;
      button {
        background-color: darkgrey;
      }
    }
  }

  .filter-group {
    margin-bottom: 15px;
  }

  .filter-group label {
    display: block;
    margin-bottom: 5px;
    color: var(--primary-color);
    font-weight: 700;
  }

  .filter-group .field-container {
    display: flex;
    gap: 10px;

    input {
      width: 100%;
    }

    input[type="checkBox"] {
      position: relative;
      right: 44px;
    }
  }

  @media (max-width: 768px) {
    .toolbar {
      flex-direction: column;
      align-items: stretch;
    }

    .search-container {
      flex-direction: column;
      align-items: stretch;
    }

    .dropdown-content {
      width: 100%;
      left: 0;
    }
  }

}