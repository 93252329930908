/* General Body Styling */
#card_selection {
  margin: 0;
  height: 100vh;
  background-image: url('https://ipfs.io/ipfs/QmZZUfgdEyMJgSfhuE98qPjcUwnXChbEbFYQtKeHx9UFNE');
  background-size: cover;
  background-position: center;
  /* Centers the GIF */
  background-attachment: fixed;
  /* Keeps the background fixed during scrolling */
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;

  .back-button {
    // position: absolute;
    // top: 20px;
    // left: 20px;
    background-color: transparent;
    color: #fff;
    border: none;
    font-size: 16px;
    cursor: pointer;
    // display: flex;
    align-items: center;

    i {
      margin-right: 5px;
      font-size: 24px;
    }

    &:hover {
      color: #ffcc00; // Change color on hover
    }
  }

  /* Carousel Container */
  .slider-container {
    height: 600px;
    /* Increased height to accommodate larger cards */
    // width: 100vw;
    max-width: 1200px;
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    position: relative;
    //margin-top: 2rem;
  }

  /* Slider Title */
  .slider-title {
    position: relative;
    //top: -17px;
    font-size: 24px;
    color: #333;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 8px 0 8px;

    .filter-modal {
      width: 100%;
    }
  }

  /* Navigation Arrows Styling */

  /* Existing styles for left-arrow and right-arrow */
  .left-arrow,
  .right-arrow {
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: arrow-slide-in 2s 1s forwards ease-out;
    z-index: 5;
    cursor: pointer;
    background-color: yellow;
    /* Set container background to yellow */
    border-radius: 50%;
    /* Optional: Make the arrow containers circular */
    transition: background-color 0.3s ease;
    /* Smooth transition for background color */
  }

  /* Positioning transforms remain unchanged */
  .left-arrow {
    transform: translate(-100vw);
  }

  .right-arrow {
    transform: translate(100vw);
  }

  /* Style the arrow icons inside the containers */
  .left-arrow i,
  .right-arrow i {
    color: black;
    /* Set arrow icon color to black */
    font-size: 24px;
    /* Ensure the arrow icons are appropriately sized */
    transition: color 0.3s ease;
    /* Smooth transition for icon color */
  }

  /* Optional: Hover Effects */

  .left-arrow:hover,
  .right-arrow:hover {
    background-color: #FFD700;
    /* Slightly different shade of yellow on hover */
  }

  .left-arrow:hover i,
  .right-arrow:hover i {
    color: #000;
    /* Ensure icon remains black or change as desired on hover */
  }

  @keyframes arrow-slide-in {
    to {
      transform: translate(0px);
    }
  }

  /* Slider Content */
  .slider-content {
    height: 100%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    overflow: hidden;
    position: relative;
    perspective: 1000px;
  }


  /* Individual Slides */
  .slider-content .slide {
    position: absolute;
    left: 50%;
    height: 500px;
    /* Updated to match card height */
    width: 300px;
    /* Updated to match card width */
    background: #999;
    border-radius: 25px;
    z-index: 0;
    opacity: 0;
    transform: translate(-50%, 0) scale(1, 1);
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out, left 0.5s ease-in-out,
      z-index 0s 0.25s ease-in-out, box-shadow 0.5s ease-in-out, filter 0.5s ease-in-out;
  }

  /* Slide Positions */
  .slide.position-1 {
    left: 20% !important;
    z-index: 1 !important;
    transform: translate(-50%, 0) scale(0.8, 0.8);
    opacity: 1 !important;
    box-shadow: 0px 0.4rem 1.6rem rgba(0, 0, 0, 0.1) !important;
    filter: blur(5px);
  }

  .slide.position-2 {
    left: 35% !important;
    z-index: 2 !important;
    transform: translate(-50%, 0) scale(0.9, 0.9);
    opacity: 1 !important;
    box-shadow: 0px 0.4rem 1.6rem rgba(0, 0, 0, 0.3) !important;
    filter: blur(2px);
  }

  .slide.position-3 {
    left: 50% !important;
    z-index: 4 !important;
    transform: translate(-50%, 0) scale(1, 1);
    opacity: 1 !important;
    box-shadow: 0px 0.4rem 1.6rem rgba(0, 0, 0, 0.5) !important;
    cursor: pointer;
    filter: blur(0px);
  }

  .slide.position-3:hover {
    box-shadow: 0px 0rem 1.8rem rgba(0, 0, 0, 0.7) !important;
    transform: translate(-50%, 0) scale(1.05, 1.05) !important;
  }

  .slide.position-4 {
    left: 65% !important;
    z-index: 2 !important;
    transform: translate(-50%, 0) scale(0.9, 0.9);
    opacity: 1 !important;
    box-shadow: 0px 0.4rem 1.6rem rgba(0, 0, 0, 0.3) !important;
    filter: blur(2px);
  }

  .slide.position-5 {
    left: 80% !important;
    z-index: 1 !important;
    transform: translate(-50%, 0) scale(0.8, 0.8);
    opacity: 1 !important;
    box-shadow: 0px 0.4rem 1.6rem rgba(0, 0, 0, 0.1) !important;
    filter: blur(5px);
  }

  .slide.position-none {
    left: 50%;
    z-index: 0;
    transform: translate(-50%, 0) scale(0.7, 0.7);
    opacity: 0;
    box-shadow: 0px 0.4rem 1.6rem rgba(0, 0, 0, 0);
  }

  /* Arrow Icons Styling */
  .slider-container i {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    transition: background 0.7s, color 0.7s;
  }

  .slider-container .left-arrow:hover i,
  .slider-container .right-arrow:hover i {
    background: rgba(22, 22, 220, 0.1);
    color: #fff;
  }

  /* Slide Interactions */
  .slider-content .slide .card-sections .upper-section,
  .slider-content .slide .card-button {
    opacity: 0;
    transition: opacity 0.6s ease-in-out;
  }

  .slider-content .slide.position-3 .card-sections .upper-section,
  .slider-content .slide.position-3:hover .card-button {
    opacity: 1;
    transition: opacity 0.6s ease-in-out;
  }

  .slide>* {
    color: white;
    font-family: 'Inter', sans-serif;
    font-size: 90%;
    letter-spacing: -0.001em;
  }

  /* Add a pulse effect when a card is selected */
  @keyframes pulse {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.03);
    }

    100% {
      transform: scale(1);
    }
  }

  // .slide.selected-card {
  //   animation: pulse 0.6s infinite !important;
  // }

  @keyframes pulseWithTranslate {
    0% {
      transform: translate(-50%, 0) scale(1);
    }

    50% {
      transform: translate(-50%, 0) scale(1.05);
    }

    100% {
      transform: translate(-50%, 0) scale(1);
    }
  }

  .slide.selected-card {
    animation: pulseWithTranslate 0.6s infinite ease-in-out !important;
  }


  /* Card Container */
  .card-container {
    width: 300px;
    /* Updated to match original card width */
    height: 500px;
    /* Updated to match original card height */
    position: relative;
    transform-style: preserve-3d;
    cursor: pointer;
  }

  /* Card Styling */
  .card {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.3s ease, box-shadow 0.3s ease, z-index 0.3s ease;
    border-radius: 15px;
    overflow: hidden;
    background-color: #111;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    background-color: #222;
    border: 2px solid transparent;
    transition: border 0.3s, box-shadow 0.3s;
  }

  /* Selected Card Highlight */
  .slide.selected-card {
    border: 3px solid #FFD700;
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.7) !important;
    border-radius: 12px;
  }

  /* Front Face */
  .confirmation-modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    .name-container {
      .fighter-name.name {
        background-color: #000;
      }
    }

    select {
      /* Reset Select */
      appearance: none;
      outline: 0;
      border: 0;
      box-shadow: none;
      /* Personalize */
      flex: 1;
      padding: 0 1em;
      color: #fff;
      background-color: var(--darkgray);
      background-image: none;
      cursor: pointer;
    }

    /* Remove IE arrow */
    select::-ms-expand {
      display: none;
    }

    /* Custom Select wrapper */
    .select {
      position: relative;
      display: flex;
      width: 15em;
      height: 3em;
      border-radius: .25em;
      overflow: hidden;
    }

    /* Arrow */
    .select::after {
      content: '\25BC';
      position: absolute;
      top: 0;
      right: 0;
      padding: 1em;
      background-color: #34495e;
      transition: .25s all ease;
      pointer-events: none;
    }

    /* Transition */
    .select:hover::after {
      color: #f39c12;
    }
  }

  /* Front Face */
  .card-front {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  /* Image Container */
  .image-container {
    width: 100%;
    height: 240px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 6px;
  }

  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-fit: fill;
    transition: transform 0.3s;
    // padding: 2px 0 0px 0;
    border-radius: 15px;
  }

  /* Name Container */
  .name-container {
    text-align: center;
    margin: 1px 0;
    padding: 5px 0;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
  }

  /* Fighter and Opponent Names */
  .fighter-name,
  .opponent-name {
    font-size: 18px;
    color: #ffffff;
    text-transform: uppercase;
    padding: 3px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    flex: 1;
    text-align: center;
    -webkit-text-stroke: 1px #000;
    text-shadow:
      1px 1px 0 #000,
      2px 2px 0 #333;
  }

  .vs {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    margin: 0 15px;
  }

  /* Stats Table */
  .stats-table {
    margin: 1px 0;
    flex-grow: 1;
  }

  .stats-table table {
    width: 100%;
    border-spacing: 6px 5px;
  }

  .stats-table td {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 8px;
    font-size: 16px;
    color: #fff;
    border-radius: 5px;
    text-align: center;

    i {
      width: auto;
      height: auto;
      display: contents;
    }

    /* Add Icon Styling */
    .add-icon {
      margin-left: 8px;
      color: #ffd700; // Gold color for the "add" icon
      cursor: pointer;
      animation: pulse 1.5s infinite ease-in-out;
      transition: transform 0.3s;
    }

    .add-icon:hover {
      transform: scale(1.2);
    }

    /* Pulse Animation */
    @keyframes pulse {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.1);
      }

      100% {
        transform: scale(1);
      }
    }
  }

  /* Card Footer */
  .card-footer {
    display: flex;
    justify-content: space-around;
    margin-top: 1px;
  }

  .rarity,
  .tier {
    padding: 10px 15px;
    border-radius: 25px;
    font-weight: bold;
    text-align: center;
    flex-grow: 1;
    color: #ffffff;
  }

  /* Rarity and Tier Styles */
  .rarity.ULTRA.RARE {
    background-color: #800080;
    box-shadow: 0 0 10px rgba(128, 0, 128, 0.8), 0 0 20px rgba(128, 0, 128, 0.6);
    border: 2px solid #ffc107;
  }

  .tier.TIER1 {
    background-color: #FFD700;
    color: #000000;
    box-shadow: 0 0 10px rgba(255, 215, 0, 0.8), 0 0 20px rgba(255, 215, 0, 0.6);
    border: 2px solid #ffc107;
  }

  .rarity.VERY.RARE {
    background-color: #FF4500;
    box-shadow: 0 0 10px rgba(255, 69, 0, 0.8), 0 0 20px rgba(255, 69, 0, 0.6);
    border: 2px solid #ffc107;
  }

  .tier.TIER2 {
    background-color: #C0C0C0;
    color: #000000;
    box-shadow: 0 0 10px rgba(192, 192, 192, 0.8), 0 0 20px rgba(192, 192, 192, 0.6);
    border: 2px solid #616160;
  }

  .rarity.STANDARD.RARE {
    background-color: #FFA500;
    box-shadow: 0 0 10px rgba(255, 165, 0, 0.8), 0 0 20px rgba(255, 165, 0, 0.6);
    border: 2px solid #ffc107;
  }

  .tier.TIER3 {
    background-color: #CD7F32;
    box-shadow: 0 0 10px rgba(205, 127, 50, 0.8), 0 0 20px rgba(205, 127, 50, 0.6);
    border: 2px solid #ffc107;
  }

  .rarity.COMMON {
    background-color: #32CD32;
    box-shadow: 0 0 10px rgba(50, 205, 50, 0.8), 0 0 20px rgba(50, 205, 50, 0.6);
    border: 2px solid #ffc107;
  }

  .tier.TIER4 {
    background-color: #1E90FF;
    box-shadow: 0 0 10px rgba(30, 144, 255, 0.8), 0 0 20px rgba(30, 144, 255, 0.6);
    border: 2px solid #0b237a;
  }

  /* Hover Effect on Image */
  .image-container:hover img {
    transform: scale(1.05);
  }

  /* Responsive Design */
  @media screen and (max-width:620px) {

    .slide.position-1,
    .slide.position-5 {
      opacity: 0.5 !important;
    }

    .slide.position-2,
    .slide.position-4 {
      opacity: 0.95 !important;
    }
  }

  @media screen and (max-width: 445px) {

    .slide.position-1,
    .slide.position-5 {
      opacity: 0 !important;
    }

    .slide.position-2,
    .slide.position-4 {
      opacity: 0.5 !important;
    }
  }

  @media screen and (max-width: 415px) {
    .slide.position-3:hover {
      box-shadow: 0px 0rem 0.8rem rgba(0, 0, 0, 0.7) !important;
      transform: translate(-50%, 0) scale(1.05, 1.05) !important;
    }

    .slide {
      opacity: 0 !important;
      box-shadow: none !important;
    }

    .slide.position-1,
    .slide.position-2,
    .slide.position-3,
    .slide.position-4,
    .slide.position-5 {
      width: 97%;
    }

    .slide.position-3 {
      box-shadow: none !important;
      opacity: 1 !important;
    }

    .slide.position-1,
    .slide.position-2 {
      left: -50% !important;
      transform: translate(-50%, 0) scale(0.7, 0.7) !important;
    }

    .slide.position-4,
    .slide.position-5 {
      left: 150% !important;
      transform: translate(-50%, 0) scale(0.7, 0.7) !important;
    }
  }

  @media screen and (max-width: 390px) {

    .left-arrow,
    .right-arrow {
      display: none;
    }

    .slider-content {
      width: 100%;
    }
  }

  /* "Choose Card" Button Styling */
  .choose-card-container {
    //margin-top: 20px;
    text-align: center;
  }

  #choose-card-button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #FFD700;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }

  #choose-card-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  #choose-card-button:hover:not(:disabled) {
    background-color: #FFC107;
    transform: scale(1.05);
  }

  #remove-card-button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #007bff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }

  #remove-card-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  #remove-card-button:hover:not(:disabled) {
    background-color: #07eaff;
    transform: scale(1.05);
  }

  /* Hide Carousel Arrows on Devices with Width Less Than or Equal to 600px */
  @media (max-width: 600px) {

    .left-arrow,
    .right-arrow {
      display: none;
    }

    /* Optional: Adjust the width of the carousel content for better fit */
    .slider-content {
      width: 100%;
      /* Increase width to utilize more screen space */
    }

    /* Optional: Adjust slide sizes for smaller screens */
    .slide {
      width: 250px;
      /* Reduce width for better fit */
      height: 400px;
      /* Adjust height accordingly */
    }
  }
}